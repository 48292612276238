import { Dayjs } from 'dayjs';
import { nine, SHELL_ENERGY_TRADING, two } from '../../constants';
import {
  BusinessData,
  DefaultOptionType,
  DropdownOption,
  EvpData,
  IpuData,
  SauData,
  SeIpuRegionData,
  TData,
  TradeData,
  VpData,
} from '../../Types/types';
import { SAU_DEFAULT_OPTION } from './constants';
// Define your types
export interface State {
  sau: string;
  evpVal: string;
  seIpuRegionVal: string;
  ipuVal: string;
  vpVal: string;
  bizVal: string;
  sauData: SauData[];
  evpData: EvpData[];
  seIpuRegionData: SeIpuRegionData[];
  ipuData: IpuData[];
  vpData: VpData[];
  businessData: BusinessData[];
  firstDate: Dayjs | null;
  lastDate: Dayjs | null;
  disableHandleFilter: boolean;
  masterData: [];
  filteredData: [];
  lookupData: [];
  isFetchingEVP: boolean;
  isFetchingSeIpuRegion: boolean;
  isFetchingIPU: boolean;
  isFetchingVP: boolean;
  isFetchingBiz: boolean;
  years: [Dayjs | null, Dayjs | null];
}
// Define your initial state
export const initialState: State = {
  sau: '',
  evpVal: '',
  seIpuRegionVal: '',
  ipuVal: '',
  vpVal: '',
  bizVal: '',
  masterData: [],
  sauData: [],
  evpData: [],
  seIpuRegionData: [],
  ipuData: [],
  vpData: [],
  businessData: [],
  firstDate: null,
  lastDate: null,
  disableHandleFilter: true,
  filteredData: [],
  lookupData: [],
  isFetchingEVP: false,
  isFetchingSeIpuRegion: false,
  isFetchingIPU: false,
  isFetchingVP: false,
  isFetchingBiz: false,
  years: [null, null],
};

//setting the current date,planning date and previous date
const yearValue = 'year';
const minDate = new Date();
const historicalStartYearDatePicker = minDate.getFullYear() - two;
minDate.setFullYear(historicalStartYearDatePicker);

const maxDate = new Date();
const planningEndYearDatePicker = maxDate.getFullYear() + nine;
maxDate.setFullYear(planningEndYearDatePicker);
export const disabledFutureDate = (current: Dayjs) => {
  return current
    ? current.isBefore(minDate, yearValue) || current.isAfter(maxDate, yearValue)
    : false;
};
export const getDropdownOptions = (dropdownOption: DropdownOption): string[] => {
  const { data, key } = dropdownOption;
  return data
    .map((val) => val[key as keyof TData]?.toString())
    .filter((item) => item !== null && item !== undefined)
    .filter((value, index, self) => self.indexOf(value) === index);
};
export const replaceEvpOption = (item: string) => {
  return item?.replace(`T&S ${SHELL_ENERGY_TRADING}`, SHELL_ENERGY_TRADING);
};
export const defaultUrl = (key: string, loggedInUserRegion: string, userEvp: string) => {
  return `${key}/?sau=${encodeURIComponent(
    SAU_DEFAULT_OPTION,
  )}&REGION=${loggedInUserRegion}&EVP=${userEvp}`;
};
export const createUrl = (key: string, selectedOption: DefaultOptionType, evpVal?: string) => {
  const sauVal = SAU_DEFAULT_OPTION;
  const children = String(selectedOption.key);
  if (key === 'EVP') {
    return `evp/?sau=${encodeURIComponent(sauVal)}`;
  } else if (key === 'SE_IPU_Region') {
    return `seIpuRegion/?sau=${encodeURIComponent(sauVal)}&evp=${encodeURIComponent(children)}`;
  } else if (key === 'BUSINESS') {
    let baseUrl = `business/?sau=${encodeURIComponent(sauVal)}&seIpuRegion=${encodeURIComponent(
      children,
    )}`;
    if (evpVal && evpVal !== 'ALL') {
      baseUrl = `${baseUrl}&evp=${encodeURIComponent(evpVal)}`;
    }
    return baseUrl;
  }
  return '';
};

export const newChartData = (state: State) => {
  const currentChartData: TradeData[] = [...state.filteredData];
  if (state.filteredData.length === 0) {
    return currentChartData;
  } else {
    return currentChartData.filter(
      (lst: TradeData) =>
        Number(lst.TRADE_YEAR) >= Number(state.firstDate) &&
        Number(lst.TRADE_YEAR) <= Number(state.lastDate),
    );
  }
};
