import { User } from 'oidc-client';
import { Dispatch, SetStateAction } from 'react';
import { Dispatch as ReduxDispatch } from '@reduxjs/toolkit';
import {
  setCpmUserType,
  setIsCpmEvpFieldDisabled,
  setIsCpmRegionFieldDisabled,
  setIsUserRegional,
  setLoggedInUserEmail,
  setLoggedInUserName,
  setLoggedInUserRegion,
  setLoggedInUserRoles,
  setShowEvpData,
  setShowScenarioModellingPage,
  setUserEvp,
  setUserRegionFlags,
} from '../../CarbonDashboard/redux/dashboard/dashboardSlice';
import { IUserInfo } from '../../CarbonDashboard/Types/types';
import { ncfReportingInternalTeamIdentifier, THREE } from '../../Tanso/api/constants';
import jwt_decode from 'jwt-decode';
import { Action, IuserRegionFlags } from '../../Tanso/Types/types';
import { IShowEvpData } from '../../CarbonDashboard/redux/dashboard/dashboard.types';
import { EVP_SE, EVP_SET, permissibleAreas } from '../../CarbonDashboard/api/constants';
import { AppDispatch } from '../../CarbonDashboard/redux/store';
import { getRegionVal } from '../../CarbonDashboard/utils';

export const getUserAction = (role: string) => {
  return role.split('_')[1];
};

export const getUserRoleAndRegion = (roles: string) => {
  const [, role, region] = roles.split('_');

  return {
    role: role.toUpperCase(),
    region: region.toUpperCase(),
  };
};

export interface IUserDetails {
  region: string;
  userId: string;
  email: string;
  roleType: string;
}

export const setUpRoleBasedAccess = (
  roles: string[],
  userId: string,
  email: string,
  dispatch: Dispatch<Action>,
  cpmDispatch: ReduxDispatch,
  isProdEnv: boolean,
) => {
  const { role: roleType, region } = getUserRoleAndRegion(roles[0]);

  // Initialize user type flags
  const userRegionFlags: IuserRegionFlags = {
    isAsiaUser: false,
    isAmericasUser: false,
    isAustraliaUser: false,
    isEuropeUser: false,
  };

  // Iterate over permissible areas to set user type flags
  Object.keys(permissibleAreas).forEach((key) => {
    if (region.includes(permissibleAreas[key as keyof typeof permissibleAreas])) {
      userRegionFlags[key as keyof typeof userRegionFlags] = true;
    }
  });

  // Example usage of userRegionFlags
  const { isAustraliaUser, isEuropeUser, isAsiaUser, isAmericasUser } = userRegionFlags;

  const userRegionVars = {
    isAustraliaUser,
    isEuropeUser,
    isAsiaUser,
    isAmericasUser,
  };
  const userDetails: IUserDetails = { region, userId, email, roleType };
  setLoggedInUserStates(dispatch, userRegionVars, isProdEnv, userDetails, cpmDispatch);
};

// The function setLoggedInUserStates is to create necessary variables from the user details received.
const setLoggedInUserStates = (
  dispatch: Dispatch<Action>,
  userRegionVars: IuserRegionFlags,
  isProdEnv: boolean,
  userDetails: IUserDetails,
  cpmDispatch: AppDispatch,
) => {
  const { isEuropeUser } = userRegionVars;
  const isGlobalUser = Object.values(userRegionVars).every((value) => value === false);
  const { region, roleType } = userDetails;
  const regionType = region.toLowerCase();
  const roleTypeLowerCase = roleType.toLowerCase();

  // check role and map the variables
  // IT Support - SECPM_ITSupport_Global
  /*
      Single view-only role covering existing CPM and NCF reporting functions.
      Data edit functions will be enabled for this role for non-prod env so that QA team can perform the testing.
      1) View-only access to Global NCF Reporting functionalities
      2) Edit access to Global NCF Reporting functionalities in lower environments only
      3) Upload access to ETRM file upload functionalities

      1) Access to Scenario Modellig: YES
      2) Data: SE + SET
      3) Region: Global
  */
  const isUserITSupport = roleTypeLowerCase.includes('itsupport');
  // edit access to IT support role in non-prod env
  const isUserITSupportInNonProd = !isProdEnv && isUserITSupport;

  // NCF Reporting Lead - SECPM_Reporting_Americas, SECPM_Reporting_Asia, SECPM_Reporting_Australia, SECPM_Reporting_Europe
  /*	
    Edit access to selected region for reporting purposes
    NCF Reporting:
      Edit access to their Regional NCF reporting
    Carbon Portfolio Dashboard:
      Access to Regional CPM charts (new!)
      No access to scenario modelling and no imported deals on Contracted vs Planned
  */
  const isUserForReporting = roleTypeLowerCase === 'reporting';

  // Global NCF Reporting Lead - SECPM_NCFLead_Global
  /*
        View access to all Regions NCF volumes
        NCF Reporting:
          View-only access to Global NCF reporting
        Carbon Portfolio Dashboard:
          Access to Global CPM charts
          No access to scenario modelling and no imported deals on Contracted vs Plan
      */
  const isUserNcfLead = roleTypeLowerCase === 'ncflead' && regionType.toLowerCase() === 'global';

  // CMF Team - Carbon Management Framework Team - All Businesses, View-only - SECPM_Team_Global
  /*
    NCF Reporting:
      View-only access to Global NCF reporting
      Carbon Portfolio Dashboard:
        Access to Global CPM charts
        Access to scenario modelling
  */
  const isUserFromCmfTeam = roleTypeLowerCase === 'team' && regionType === 'global';

  // SE Leadership - Shell Energy Leadership - SECPM_LT_Global
  /*
          NCF Reporting:
            View-only access to Global NCF reporting
          Carbon Portfolio Dashboard:
            Access to Global CPM charts with SE-only data
            Access to scenario modelling with SE-only data
        */
  const isUserFromLeadership = roleTypeLowerCase === 'lt' && regionType === 'global';

  // Business VPs & GMs x4 - Individuals responsible for a specific line of business and for a specific commodity line of business - SECPM_VPGM_Global
  /*
          SE VPs & GMs x4 - Access to Regional CPM charts with SE-only data - Access to scenario modelling with SE-only data
            SECPM_SE-VPGM_Americas
            SECPM_SE-VPGM_Asia
            SECPM_SE-VPGM_Australia
            SECPM_SE-VPGM_Europe
      
          SET VPs & GMs x4 - Access to Regional CPM charts with SET-only data - Access to scenario modelling with SET-only data
            SECPM_SET-VPGM_Americas
            SECPM_SET-VPGM_Asia
            SECPM_SET-VPGM_Australia
            SECPM_SET-VPGM_Europe
      
          NCF Reporting:
            View-only access to Regional NCF reporting (new!)
          Carbon Portfolio Dashboard:
            Access to Regional CPM charts
            Access to scenario modelling
        */
  const isUserSeVpGm = roleTypeLowerCase === 'se-vpgm';
  const isUserSetVpGm = roleTypeLowerCase === 'set-vpgm';

  dispatch({ type: 'setIsUserForReporting', payload: isUserForReporting });
  dispatch({ type: 'setIsUserSeVpGm', payload: isUserSeVpGm });

  const ncfRoles = {
    isEuropeUser,
    isGlobalUser,
    isUserITSupport,
    isUserITSupportInNonProd,
    isUserForReporting,
    isUserNcfLead,
  };
  const cpmRoles = {
    isUserFromCmfTeam,
    isUserFromLeadership,
    isUserSeVpGm,
    isUserSetVpGm,
    isUserForReporting,
    isUserNcfLead,
    isUserITSupport,
  };
  setNcfReportingUserStates(dispatch, ncfRoles, userRegionVars, userDetails);
  setCpmUserStates(cpmDispatch, cpmRoles, userRegionVars, userDetails);
};

export const setNcfReportingUserStates = (
  dispatch: Dispatch<Action>,
  ncfRoles: {
    isEuropeUser: boolean;
    isGlobalUser: boolean;
    isUserITSupport: boolean;
    isUserITSupportInNonProd: boolean;
    isUserForReporting: boolean;
    isUserNcfLead: boolean;
  },
  userRegionVars: {
    isAustraliaUser: boolean;
    isEuropeUser: boolean;
    isAsiaUser: boolean;
    isAmericasUser: boolean;
  },
  userDetails: {
    region: string;
    userId: string;
    email: string;
    roleType: string;
  },
) => {
  const {
    isEuropeUser,
    isUserITSupport,
    isUserITSupportInNonProd,
    isUserForReporting,
    isUserNcfLead,
  } = ncfRoles;
  const { region, userId, email, roleType } = userDetails;
  const validRegion = region.toLocaleLowerCase() === 'global' ? '' : region;
  const isUserWithActions = isUserITSupportInNonProd || isUserForReporting; //user who can take actions like editting data, create/update snapshot, create configure rule
  const isNcfReportingRegionFieldDisabled = region.toLocaleLowerCase() !== 'global';

  // flags for to show user action buttons
  const showConfigRulePage = isEuropeUser || isUserITSupport;

  dispatch({
    type: 'setUserType',
    payload: {
      isUserITSupport,
      isUserITSupportInNonProd,
      isUserForReporting,
      isUserNcfLead,
    },
  });
  dispatch({
    type: 'setIsNcfReportingRegionFieldDisabled',
    payload: isNcfReportingRegionFieldDisabled,
  });
  dispatch({ type: 'setShowConfigRulePage', payload: showConfigRulePage });
  dispatch({ type: 'setIsUserWithActions', payload: isUserWithActions });
  // set user details like mail, username, region and role
  dispatch({ type: 'setRole', payload: roleType });
  dispatch({ type: 'setMail', payload: email });
  dispatch({ type: 'setUsername', payload: userId });
  dispatch({ type: 'setUserRegion', payload: validRegion });
  dispatch({ type: 'setUserRegionFlags', payload: userRegionVars });
};

export const setCpmUserStates = (
  cpmDispatch: ReduxDispatch,
  cpmRoles: {
    isUserFromCmfTeam: boolean;
    isUserSeVpGm: boolean;
    isUserSetVpGm: boolean;
    isUserForReporting: boolean;
    isUserNcfLead: boolean;
    isUserITSupport: boolean;
    isUserFromLeadership: boolean;
  },
  userRegionVars: {
    isAustraliaUser: boolean;
    isEuropeUser: boolean;
    isAsiaUser: boolean;
    isAmericasUser: boolean;
  },
  userDetails: {
    region: string;
    userId: string;
    email: string;
    roleType: string;
  },
) => {
  const {
    isUserFromCmfTeam,
    isUserSeVpGm,
    isUserSetVpGm,
    isUserForReporting,
    isUserNcfLead,
    isUserITSupport,
    isUserFromLeadership,
  } = cpmRoles;
  const { region, userId, email, roleType } = userDetails;
  const showEvpData: IShowEvpData = {
    showSeData: isUserITSupport || isUserFromCmfTeam || isUserFromLeadership || isUserSeVpGm,
    showSetData:
      isUserITSupport ||
      isUserForReporting ||
      isUserNcfLead ||
      isUserFromCmfTeam ||
      isUserFromLeadership ||
      isUserSetVpGm,
    showAllData: true,
  };
  let userEvp = '';

  if (showEvpData.showSeData && !showEvpData.showSetData) {
    userEvp = EVP_SE;
  } else if (showEvpData.showSetData && !showEvpData.showSeData) {
    userEvp = EVP_SET;
  }
  //showAllData must be true when showSeData and showSetData are either both true or both false.
  showEvpData.showAllData = showEvpData.showSeData === showEvpData.showSetData;

  let isEvpFieldDisabled = false;

  if (
    (showEvpData.showSeData && !showEvpData.showSetData) ||
    (!showEvpData.showSeData && showEvpData.showSetData)
  ) {
    isEvpFieldDisabled = true;
  }
  const isRegionFieldDisabled = region.toLowerCase() !== 'global';
  const showScenarioModellingPage = !isUserForReporting && !isUserNcfLead;

  const userType = {
    isUserFromCmfTeam,
    isUserSeVpGm,
    isUserSetVpGm,
  };

  cpmDispatch(setShowEvpData(showEvpData));
  cpmDispatch(setIsCpmEvpFieldDisabled(isEvpFieldDisabled));
  cpmDispatch(setCpmUserType(userType));
  cpmDispatch(setIsCpmRegionFieldDisabled(isRegionFieldDisabled));
  // here setting just one role selected by the user
  cpmDispatch(setLoggedInUserRoles(roleType));
  cpmDispatch(setLoggedInUserName(userId));
  cpmDispatch(setLoggedInUserEmail(email));
  cpmDispatch(setUserRegionFlags(userRegionVars));
  cpmDispatch(setIsUserRegional(Object.values(userRegionVars).some((item) => item)));
  cpmDispatch(setUserEvp(userEvp));
  cpmDispatch(setLoggedInUserRegion(getRegionVal(userRegionVars)));
  cpmDispatch(setShowScenarioModellingPage(showScenarioModellingPage));
};

export const mapUserRoles = (
  setUser: Dispatch<SetStateAction<IUserInfo>>,
  userDetails: User,
  dispatch: Dispatch<Action>,
  cpmDispatch: ReduxDispatch,
) => {
  const currentUser: IUserInfo = jwt_decode(userDetails.access_token);
  setUser(currentUser);
  const userMail = currentUser.email;
  dispatch({ type: 'setUserMail', payload: userMail });
  // get all roles from Log in data
  const userRolesFromLoginData = currentUser.realm_access.roles;
  // get all the allowed roles for the application
  const allowedRoles = getAllowedRoles(userRolesFromLoginData);
  const isUnauthorizedUser = allowedRoles.length === 0;
  const updatedUserRole = isUnauthorizedUser ? ['unauthorized'] : allowedRoles;

  // set Tanso user roles
  dispatch({
    type: 'setRole',
    payload: updatedUserRole,
  });

  // set CPM user roles - after this, all the roles will be shows to the user from SelectRolePage
  const sortedRoleList = updatedUserRole.sort((a, b) => a.localeCompare(b));
  cpmDispatch(setLoggedInUserRoles(sortedRoleList));
};

export const roleIdentifier = 'SECPM';

// getAllowedRoles takes the roles received from DEP SSO and returns only those roles which starts with 'SECPM'
export const getAllowedRoles = (roles: string[]) => {
  return roles.filter((item: string) => {
    const parts = item.split('_');
    return item.startsWith(roleIdentifier) && parts.length === THREE; // checking the length coz to strike out roles like SECPM_Superuser. We need regionType here for sure.
  });
};
