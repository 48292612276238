import React, { useCallback, useEffect, useState } from 'react';
import {
  DividerPadding,
  OverAllContainer,
  ScenerioModellingContainer,
  SubTitle,
  Title,
  VisualScenerioFlex,
} from './ScenerioModelling.styles';
import Footer from '../Footer/Footer';
import ScenerioModellingChart from '../charts/ScenerioModellingChart';
import { convertValue } from './conversionutils';
import {
  fetchNetCarbonIntensity,
  fetchbasecaseOverallPortfolio,
  fetchbasecasetabledata,
  getImportedDeals,
} from '../../api/services';
import Loader from '../Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showSpinner, hideSpinner } from '../../redux/datavisualization/datavisualizationSlice';
import { ALL_COUNTRIES, ALL_REGIONS, UNITED_STATES } from '../../api/constants';
import {
  BUSINESS_PLAN_KEY,
  DEAL_FUNNEL_KEY,
  one,
  SCENERIO_MODELLING_SUB_TITLE,
  SCENERIO_MODELLING_TITLE,
  two,
} from '../../constants';
import {
  Area,
  ChartData,
  CommodityDataEntry,
  ICategoryData,
  IChartDataItem,
  IGroupNCI,
  ImportedDeals,
  IProduct,
  IRevertToOrignalData,
  TableData,
  TransformedCommodityItem,
} from '../../Types/types';
import {
  addRecordIfNotExists,
  calculateCountryData,
  calculateNCIData,
  findDataItemByRegionAndCategory,
  findYearDataByIdAndYear,
  getNetCarbonIntensity,
  getTransformedTableData,
  revertChartDataWithEmissionModification,
  revertNciChartData,
  transformCommodityData,
  transformMovedDealsChart,
  transformRegionData,
  updateChartDataWithEmissionModification,
  getMergedCommodity,
  getMergedOverllPortfolio,
  addEmptyValueForKpiDeals,
  exampleData,
  getNciChartData,
  getDealFunnelOptions,
  getBusinessPlanOptions,
  convertCarbonEmissionInMTPA,
  handleInputValAndChartOnTabChange,
} from './utils';
import NetCarbonIntensity from '../charts/NetCarbonIntensity/NetCarbonIntensity';
import ScenarioModellingTabs from './ScenarioModellingTabs/ScenarioModellingTabs';
import { setAddedImportedDealsId } from '../../redux/dashboard/dashboardSlice';
import { currentYear } from '../../utils/utils';
import { formatRegionName } from '../../utils';

export interface SubRegion {
  id: number;
  value: string;
}

const ScenerioModelling: React.FC = () => {
  const [selectedRegion, setSelectedRegion] = useState(ALL_REGIONS);
  const [selectedSubRegion, setSelectedSubRegion] = useState(UNITED_STATES);
  const [subRegionOptions, setSubRegionOptions] = useState<SubRegion[]>([]);
  const [regionData, setRegionData] = useState<IProduct[] | undefined>(undefined);
  const [allRegionData, setAllRegionData] = useState<IProduct[]>();
  const [scenerioChartData, setScenerioChartData] = useState<Area[]>(exampleData);
  const [nciTransformData, setNciTransformData] = useState<Area[]>(exampleData);
  const [mergedOverllPortfolio, setMergedOverllPortfolio] = useState<CommodityDataEntry[]>([]);
  const [importedDealApiCalled, setImportedDealApiCalled] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    nciData,
    importedDeals,
    addedImportedDealsId,
    tabKey,
    userRegionFlags,
    loggedInUserRegion,
    userEvp,
  } = useAppSelector((state) => state.dashboard);
  const [netCarbonIntensityData, setNetCarbonIntensityData] = useState<IGroupNCI[]>(nciData);
  const [importedDealsData, setImportedDealsData] = useState<ImportedDeals[]>(importedDeals);
  const [revertToOriginalData, setRevertToOriginalData] = useState<IRevertToOrignalData[]>([]);
  const fetchsceneriodata = async () => {
    dispatch(showSpinner());
    const regions = ['AMERICAS', 'ASIA', 'AUSTRALIA', 'EUROPE', 'GLOBAL'];
    const [carbonintensityTargetResp, tableDataResp] = await Promise.all([
      fetchbasecaseOverallPortfolio(loggedInUserRegion, userEvp),
      fetchbasecasetabledata(regions),
    ]);
    if (!nciData.length) {
      const carbonIntensity = await fetchNetCarbonIntensity(loggedInUserRegion, userEvp);
      carbonIntensity && setNetCarbonIntensityData(carbonIntensity?.data);
    }

    carbonintensityTargetResp && setMergedOverllPortfolio(carbonintensityTargetResp.data);
    carbonintensityTargetResp && scenarionChart(carbonintensityTargetResp.data, []);
    const tableData = tableDataResp?.data;
    const sortedData: TableData[] = tableData?.reduce(transformRegionData, []);
    const transformedTableData = sortedData && getTransformedTableData(sortedData);
    setRegionData(transformedTableData);
    transformedTableData && handleTabChange(loggedInUserRegion || ALL_REGIONS);
    dispatch(hideSpinner());
  };
  useEffect(() => {
    (async () => {
      if (!importedDealsData.length && !importedDealApiCalled) {
        const importedDealsRes = await getImportedDeals(loggedInUserRegion, userEvp);
        importedDealsRes && setImportedDealsData(importedDealsRes?.data);
        setImportedDealApiCalled(true);
      }
    })();
  }, [importedDealsData, importedDealApiCalled, loggedInUserRegion, userEvp]);
  const initialNciChartData = useCallback(() => {
    const netCarbonIntensity = getNetCarbonIntensity(
      netCarbonIntensityData as unknown as IGroupNCI[],
    );
    setNciTransformData([...netCarbonIntensity]);
  }, [netCarbonIntensityData]);
  useEffect(() => {
    initialNciChartData();
  }, [netCarbonIntensityData, initialNciChartData]);

  const scenarionChart = (
    overAllPortfoliodata: CommodityDataEntry[],
    importedDealsScenario: ImportedDeals[],
  ) => {
    const mergedOverllPortfolioData = getMergedOverllPortfolio(overAllPortfoliodata);
    const movedDealsChartData = transformMovedDealsChart(importedDealsScenario);
    const movedDealsChartDataWithNull =
      movedDealsChartData && addEmptyValueForKpiDeals(movedDealsChartData);

    const filterNonEmptyData = (x: { data: TransformedCommodityItem[] }): boolean =>
      x.data.length > 0;
    const endYear = 2033;
    const nonemptyData =
      mergedOverllPortfolioData &&
      addRecordIfNotExists(mergedOverllPortfolioData, currentYear - two, endYear);
    const transformedData =
      nonemptyData &&
      movedDealsChartDataWithNull &&
      [...nonemptyData, ...movedDealsChartDataWithNull]
        ?.map(transformCommodityData)
        .filter(filterNonEmptyData)
        .filter((item: { name: string }) => item.name !== 'LNG');
    const mergedCommodityData = transformedData && getMergedCommodity(transformedData);
    const transformedbudgetlineData = {
      type: 'line',
      color: '#D93638',
      name: 'Total Plan',
      dashStyle: 'Dash',
      data: mergedCommodityData,
    };
    transformedData && setScenerioChartData([...transformedData, transformedbudgetlineData]);
  };
  useEffect(() => {
    fetchsceneriodata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const filteredDeals = importedDealsData.filter((deal) =>
      addedImportedDealsId.some((opportunityId) => opportunityId === deal.OPPORTUNITY_ID),
    );
    const updatedFilter = filteredDeals.map((deal) => {
      const { CARBON_EMISSION_UNIT } = deal;
      if (CARBON_EMISSION_UNIT === 'MTPA') {
        return deal;
      } else {
        return convertCarbonEmissionInMTPA(deal);
      }
    }) as ImportedDeals[];
    mergedOverllPortfolio.length && scenarionChart(mergedOverllPortfolio, updatedFilter);
    filteredDeals.length
      ? setNciTransformData(getNciChartData(filteredDeals, nciTransformData))
      : initialNciChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedImportedDealsId]);

  useEffect(() => {
    const sampleDataAdded = regionData && calculateCountryData(regionData);

    if (sampleDataAdded) {
      const sortedData = sampleDataAdded.map((data: IProduct) => {
        const sortedCategoryData = [...data.categoryData].sort((a, b) =>
          a.category.localeCompare(b.category),
        );
        return { ...data, categoryData: sortedCategoryData };
      });
      setAllRegionData(sortedData);
    }
  }, [regionData]);

  const handleTabChange = (region: string) => {
    const subregions =
      tabKey === DEAL_FUNNEL_KEY
        ? getDealFunnelOptions(importedDealsData, region, userRegionFlags)
        : getBusinessPlanOptions(regionData as IProduct[], region, userRegionFlags);
    if (subregions) {
      let defaultSubRegion = '';
      if (tabKey === BUSINESS_PLAN_KEY && region === 'AMERICAS') {
        defaultSubRegion = UNITED_STATES;
      } else if (tabKey === BUSINESS_PLAN_KEY) {
        defaultSubRegion = formatRegionName(subregions[0].value);
      } else {
        defaultSubRegion = ALL_COUNTRIES;
      }
      setSubRegionOptions(subregions);
      setSelectedSubRegion(defaultSubRegion);
      setSelectedRegion(region);
    }
  };

  const handleValueChange = (
    region: string,
    subregion: string,
    category: string,
    product: string,
    productId: string,
    year: number,
    newValue: number,
  ): void => {
    if (!regionData) {
      return undefined;
    }
    const regionDataCopy: IProduct[] = [...regionData];
    const regionItem = findDataItemByRegionAndCategory(regionDataCopy, region, subregion);

    if (!regionItem) {
      return undefined;
    }

    const categoryDataCopy: ICategoryData[] = [...regionItem.categoryData];
    const commYeardata = findYearDataByIdAndYear(categoryDataCopy, productId, year);
    const yearData = commYeardata?.yearsData;
    const commodityName = commYeardata?.commodity;
    if (!yearData) {
      return undefined;
    }
    const {
      id,
      originalValue,
      unit,
      carbonIntensityUom,
      carbonIntensity,
      originalEmission,
      changedEmission,
    } = yearData;
    setRevertToOriginalData((prevState) => [
      ...prevState,
      { rowId: id, category, product, year, region: selectedRegion, subRegion: selectedSubRegion },
    ]);
    const carbonVolumeinunit = convertValue(
      newValue,
      unit,
      carbonIntensityUom.split('/')[1].toLowerCase(),
      commodityName,
    );
    const carbonEmissionInMTPA = convertValue(
      (carbonVolumeinunit ?? 0) * carbonIntensity,
      carbonIntensityUom.split('/')[0].toLowerCase(),
      'mtpa',
      commodityName,
    );
    const deltaVolume = newValue !== originalValue ? newValue - originalValue : newValue; // gwh
    const deltaVolumeInMj = convertValue(deltaVolume, unit, `mj${year}`, commodityName);

    const deltaVolumeInMwh = convertValue(deltaVolume, unit, 'mwh', commodityName);
    const updatedNciData = calculateNCIData(
      deltaVolumeInMj as number,
      deltaVolumeInMwh as number,
      year,
      carbonIntensity ?? null,
      nciTransformData,
      commodityName as string,
    );
    setNciTransformData(updatedNciData);
    const updatedChartData = updateChartDataWithEmissionModification(
      scenerioChartData as IChartDataItem[],
      category,
      product,
      year,
      originalEmission,
      changedEmission,
      carbonEmissionInMTPA,
    );

    setScenerioChartData(updatedChartData as Area[]);
    const updatedYearData = { ...yearData };
    updatedYearData.changedValue = newValue;
    updatedYearData.changedEmission = carbonEmissionInMTPA;

    regionDataCopy.forEach((item) => {
      item.categoryData.forEach((categoryData) => {
        categoryData.yearsdata = categoryData.yearsdata.map((data) => {
          if (data.id === productId) {
            return updatedYearData;
          }
          return data;
        });
      });
    });
    setRegionData(regionDataCopy);
  };

  const handleRevertToOriginal = (
    region: string,
    subregion: string,
    category: string,
    product: string,
    productId: string,
    year: number,
  ) => {
    const revertedData = revertNciChartData(nciTransformData, year);
    setNciTransformData(revertedData);
    const updatedRegionData = regionData?.map((data) => {
      if (data.region === region && data.subregion === subregion) {
        const updatedCategories = data.categoryData.map((categoryData) => {
          if (categoryData.category === category) {
            const updatedYearsData = categoryData.yearsdata.map((yearData) => {
              if (yearData.id === productId && yearData.year === year) {
                const updatedChartData = revertChartDataWithEmissionModification(
                  scenerioChartData as IChartDataItem[],
                  category,
                  product,
                  year,
                  yearData.originalEmission,
                  yearData.changedEmission,
                );
                setScenerioChartData(updatedChartData as Area[]);
                return {
                  ...yearData,
                  changedValue: yearData.originalValue,
                  changedEmission: yearData.originalEmission,
                };
              }
              return yearData;
            });
            return { ...categoryData, yearsdata: updatedYearsData };
          }
          return categoryData;
        });
        return { ...data, categoryData: updatedCategories };
      }
      return data;
    });

    setRegionData(updatedRegionData);
  };
  const selectedRegionData =
    selectedRegion !== ALL_REGIONS
      ? regionData?.find(
          (data) => data.region === selectedRegion && data.subregion === selectedSubRegion,
        )
      : allRegionData?.find((data) => data.region === selectedRegion);
  const handleValueChangeWrapper = (
    rowId: string,
    category: string,
    product: string,
    year: number,
    newValue: number,
  ) => {
    handleValueChange(selectedRegion, selectedSubRegion, category, product, rowId, year, newValue);
  };

  const handleRevertToOriginalWrapper = (
    rowId: string,
    category: string,
    product: string,
    year: number,
  ) => {
    handleRevertToOriginal(selectedRegion, selectedSubRegion, category, product, rowId, year);
  };

  const handleRevertAllToOriginal = () => {
    fetchsceneriodata();
    initialNciChartData();
    dispatch(setAddedImportedDealsId([]));
    handleTabChange(loggedInUserRegion || ALL_REGIONS);
  };

  useEffect(() => {
    // We will optimize this later to avoid api call when tab change to business plan for reset table.
    if (tabKey === BUSINESS_PLAN_KEY) {
      revertToOriginalData.length === one
        ? revertToOriginalData.forEach((revertToOriginalObj) => {
            const { rowId, category, product, year, region, subRegion } = revertToOriginalObj;
            handleRevertToOriginal(region, subRegion, category, product, rowId, year);
          })
        : handleRevertAllToOriginal();
      setRevertToOriginalData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  useEffect(() => {
    if (tabKey === BUSINESS_PLAN_KEY) {
      const regionWiseData =
        selectedRegion === ALL_REGIONS
          ? regionData
          : regionData?.filter(
              (data) => data.region === selectedRegion && data.subregion === selectedSubRegion,
            );
      const updatedData =
        regionWiseData && handleInputValAndChartOnTabChange(regionWiseData, scenerioChartData);
      updatedData && setScenerioChartData(updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);
  return (
    <>
      <ScenerioModellingContainer>
        <Loader data-testid='loader' />
        <div>
          <Title>{SCENERIO_MODELLING_TITLE}</Title>
          <SubTitle>{SCENERIO_MODELLING_SUB_TITLE}</SubTitle>
        </div>
        <VisualScenerioFlex>
          <OverAllContainer>
            <ScenerioModellingChart
              scenerioModellingdata={scenerioChartData as ChartData[]}
              chartType='scenerio'
            />
          </OverAllContainer>
          <OverAllContainer>
            <NetCarbonIntensity netCarbonIntensity={nciTransformData as ChartData[]} />
          </OverAllContainer>
        </VisualScenerioFlex>
        <DividerPadding />
        <ScenarioModellingTabs
          selectedRegion={selectedRegion}
          handleTabChange={handleTabChange}
          subRegionOptions={subRegionOptions}
          selectedSubRegion={selectedSubRegion}
          setSubRegionOptions={setSubRegionOptions}
          setSelectedSubRegion={setSelectedSubRegion}
          setSelectedRegion={setSelectedRegion}
          handleRevertAllToOriginal={handleRevertAllToOriginal}
          selectedRegionData={selectedRegionData as IProduct}
          handleValueChangeWrapper={handleValueChangeWrapper}
          handleRevertToOriginalWrapper={handleRevertToOriginalWrapper}
          importedDealsData={importedDealsData}
          regionData={regionData as IProduct[]}
        />
      </ScenerioModellingContainer>
      <Footer />
    </>
  );
};

export default ScenerioModelling;
