import { Button, Modal, ModalSize } from '@sede-x/shell-ds-react-framework';
import { VIEW_IMPORTED_DEALS } from '../../constants';
import { memo, useEffect, useState } from 'react';
import {
  getColumns,
  getFilterDataByCommodityAndEvp,
  getSelectedYearImportedDeals,
  getTransformedImportedDeals,
} from './importedDealsUtils';
import ImportedDealModalTitle from './ImportedDealModalTitle';
import { ICustomColumn, ImportedDeals } from '../../Types/types';
import { ImportedDealsContainer } from './ImportedDeals.styles';
import './ImportedDeals.css';
import ImportedDealsTable from '../ImportedDealTable/ImportedDealsTable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getImportedDeals } from '../../api/services';
import { setImportedDealsData } from '../../redux/dashboard/dashboardSlice';

const ViewImportedDeals = (props: {
  selectedCommodity: string;
  selectedEvp: string;
  isImportedDealsShow: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const { selectedCommodity, selectedEvp, isImportedDealsShow } = props;
  const { importedDeals, loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const getImportedDealsData = async () => {
    if (!importedDeals?.length) {
      const response = await getImportedDeals(loggedInUserRegion, userEvp);
      response && dispatch(setImportedDealsData(response?.data));
    }
  };
  useEffect(() => {
    getImportedDealsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedYear(null);
  };
  const tranformedImportedDeals = getTransformedImportedDeals(importedDeals) as ImportedDeals[];
  const filterDealsByCommodity = getFilterDataByCommodityAndEvp(
    tranformedImportedDeals,
    selectedCommodity,
    userEvp ?? selectedEvp,
  );
  const selectedYearImportedDeals =
    filterDealsByCommodity && getSelectedYearImportedDeals(filterDealsByCommodity, selectedYear);

  return (
    <ImportedDealsContainer data-testid='imported-deals'>
      <Button
        disabled={!isImportedDealsShow}
        onClick={handleButtonClick}
        size='small'
        variant='outlined'
      >
        {VIEW_IMPORTED_DEALS}
      </Button>
      <Modal
        bodyPadding={false}
        className='imported-deals-modal'
        title={
          <ImportedDealModalTitle selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
        }
        description={`No. of Imported Deals: ${selectedYearImportedDeals?.length}`}
        open={isModalOpen}
        onClose={handleCloseModal}
        size={'large' as ModalSize}
        width='98%'
      >
        <ImportedDealsTable
          importedDeals={selectedYearImportedDeals as ImportedDeals[]}
          cols={getColumns(selectedYear) as ICustomColumn[]}
        />
      </Modal>
    </ImportedDealsContainer>
  );
};

export default memo(ViewImportedDeals);
