import { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { fetchAvgMarginToCarbonRatio } from '../../api/services';
import Chart from '../Chart/Chart';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hideSpinner, showSpinner } from '../../redux/datavisualization/datavisualizationSlice';
import { useNavigate } from 'react-router-dom';
import { toolTipFormatter } from '../../utils';
import { CHART_AXIS_STYLE } from '../../constants';
import { ViewDetailsDiv } from './index.styles';
import ViewDetailsButton from '../ViewDetailsButton';
import {
  ChartContainer,
  StyledContainer,
  StyledDiv,
} from '../charts/ScenerioModellingChart/index.styles';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import { PointsData } from '../../Types/types';

const fontFamilyValue = 'Shell Font, Arial, sans-serif';
const AverageMarginToCarbonRatio = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  type TradeData = {
    TRADE_COMMODITY_NAME: string;
    TRADE_YEAR: number;
    SOURCE: string;
    CARBON_EMISSION: number;
    CARBON_BUDGET: number | null;
  };

  type AverageCarbon = { IPU: string; MCR: number; SOURCE: string };

  const [avgCarbon, setAvgCarbon] = useState<AverageCarbon[]>([]);
  const [source, setSource] = useState<string>();

  const getUniqueSources = (tradeData: TradeData[]): string => {
    const uniqueSources: string[] = [];
    let sourcedata = '';
    for (const data of tradeData) {
      if (!uniqueSources.includes(data.SOURCE)) {
        uniqueSources.push(data.SOURCE);
      }
    }
    for (const unique of uniqueSources) {
      sourcedata += unique + ', ';
    }
    const charsToRemove = 2;
    return sourcedata.substring(0, sourcedata.length - charsToRemove);
  };

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      try {
        const avgmargintocarbonratio = await fetchAvgMarginToCarbonRatio(
          loggedInUserRegion,
          userEvp,
        );
        const avgmargintoCarbon = avgmargintocarbonratio.data;
        const avgmargintoCarbonNonHydrogen = avgmargintoCarbon.filter(
          (carbon: { IPU: string }) => carbon.IPU !== 'Hydrogen',
        );
        setAvgCarbon(avgmargintoCarbonNonHydrogen);
        const uniqueSources: string = getUniqueSources(avgmargintoCarbonNonHydrogen);
        setSource(uniqueSources);
      } catch {
        notify('error', FETCH_ERROR);
        dispatch(hideSpinner());
        // handle error here
      }
      dispatch(hideSpinner());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 500,
      marginBottom: 80,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px', // Adjust the font size here as per your requirement
        fontWeight: 'light',
        color: '#757575',
        fontFamily: fontFamilyValue,
      },
    },
    title: {
      text: 'Average Planning MCR per IPU',
      align: 'left',
      y: 20,
      style: {
        fontSize: '20px',
        fontFamily: fontFamilyValue,
      },
    },
    subtitle: {
      text: 'Margin to Carbon Ratio (MCR1 - 10-year average) for each Integrated<br /> Performance Unit.',
      align: 'left',
      style: {
        fontSize: '14px',
        fontFamily: fontFamilyValue,
      },
    },

    yAxis: {
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: 'Average Margin to Carbon Ratio (USD/Tonne)',
      },
      gridLineWidth: 1,
      min: 0,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    xAxis: {
      categories: avgCarbon.map((item: AverageCarbon) => item.IPU),
      gridLineWidth: 1,
      title: {
        useHTML: true,
        text: 'IPU',
        style: CHART_AXIS_STYLE,
      },
    },

    series: [
      {
        type: 'column',
        color: '#0097BB',
        data: avgCarbon.map((item: AverageCarbon) => item.MCR),
        name: 'IPU',
        showInLegend: false,
      },
    ],
  };

  return (
    <StyledContainer>
      <ViewDetailsDiv>
        <ViewDetailsButton handleClick={() => navigate('/marginToCarbonDetails')} float='right' />
      </ViewDetailsDiv>
      <StyledDiv>
        <ChartContainer>
          <Chart
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
            source={source}
            chartKey='AveragePlanningMCRPerIPU'
          />
        </ChartContainer>
      </StyledDiv>
    </StyledContainer>
  );
};

export default AverageMarginToCarbonRatio;
