import { TooltipFormatterContextObject } from 'highcharts';
import { COMMODITY_NG_LONG_NAME } from '../../../../../../api/constants';
export interface MapDataItem {
  YEAR: number;
  QUARTER: string;
  UNIT: string;
  QTY: number;
  PARAMETER_CD: string;
}

export interface IRegionData {
  PARAMETER_CD: string;
  QTY: number;
  QUARTER?: string;
  YEAR?: number;
}

export interface ISeriesData {
  name: string;
  data: number[];
}

export interface IRegionQuarterData extends IRegionData {
  QUARTER: string;
  YEAR: number;
}

export type VolumeDataItem = {
  year: string;
  quarter: string;
  quantity: number;
  outOfScopeQuantity: number;
  tradeName: string;
};

export type ProcessedVolumeData = {
  inScopeVolume: number;
  outOfScopeVolume: number;
  totalVolume: number;
  timePeriod: string;
};

interface Series {
  name: string;
  data: number[] | { x: number; y: number }[];
  color: string;
}

interface DataItem {
  tradeName: string;
  quarter: string;
  quantity: number;
}

export interface Column {
  header: string;
  accessorKey: string;
}

export const columns: Column[] = [
  {
    header: 'Time Period',
    accessorKey: 'timePeriod',
  },
  {
    header: 'In-Scope Volume',
    accessorKey: 'inScopeVolume',
  },
  {
    header: 'Out of Scope Volume',
    accessorKey: 'outOfScopeVolume',
  },
  {
    header: 'Total Volume',
    accessorKey: 'totalVolume',
  },
];

export const processVolumeData = (
  rawData: VolumeDataItem[],
  tradeName: string,
): ProcessedVolumeData[] => {
  const processed = rawData
    .filter((item) => item.tradeName === tradeName)
    .reduce<Record<string, Omit<ProcessedVolumeData, 'timePeriod'>>>(
      (acc, { year, quarter, quantity, outOfScopeQuantity }) => {
        const timePeriod = `${year}-${quarter}`;
        if (!acc[timePeriod]) {
          acc[timePeriod] = {
            inScopeVolume: 0,
            outOfScopeVolume: 0,
            totalVolume: 0,
          };
        }
        acc[timePeriod].inScopeVolume += quantity;
        acc[timePeriod].outOfScopeVolume += outOfScopeQuantity;
        acc[timePeriod].totalVolume += quantity + outOfScopeQuantity;
        acc[timePeriod].inScopeVolume = +acc[timePeriod].inScopeVolume.toFixed(2);
        acc[timePeriod].outOfScopeVolume = +acc[timePeriod].outOfScopeVolume.toFixed(2);
        acc[timePeriod].totalVolume = +acc[timePeriod].totalVolume.toFixed(2);

        return acc;
      },
      {},
    );

  return Object.entries(processed).map(([timePeriod, data]) => ({
    timePeriod,
    ...data,
  }));
};

export const getNaturalGasVolumeData = (volumes: VolumeDataItem[]) =>
  processVolumeData(
    volumes.map((volume) => ({
      ...volume,
      year: volume.year.toString(),
    })),
    COMMODITY_NG_LONG_NAME,
  );

export const getPowerVolumeData = (volumes: VolumeDataItem[]) =>
  processVolumeData(
    volumes.map((volume) => ({
      ...volume,
      year: volume.year.toString(),
    })),
    'Power',
  );

export const tooltipFormatter = (context: TooltipFormatterContextObject) => {
  const y: string | number = context.y ?? '';
  const volume = Math.round(Number(y) * 100) / 100;
  const parameter: string = context?.series?.name ?? '';
  const x: string | number = context.x ?? '';
  return `<b>Qty:</b> ${volume}<br/>
            <b>Year Quarter:</b> ${x}<br/>
            <b>Parameter Name:</b> ${parameter}`;
};

export const groupAndFormatData = (data: DataItem[]): Series[] => {
  //Prepare and Combine highchart column chart and line chart series data
  //Line chart to display dotted lines in betwwen categories
  const categories = getBarChartCategories(data);
  const groupedData = data.reduce((acc: Record<string, number[]>, item: DataItem) => {
    if (!acc[item.tradeName]) {
      acc[item.tradeName] = Array(categories.length).fill(0);
    }
    const quarterIndex = parseInt(item.quarter.slice(1), 10) - 1;
    acc[item.tradeName][quarterIndex] = item.quantity;
    return acc;
  }, {});

  const columnChartSeriesData = Object.keys(groupedData).map((tradeName) => ({
    name: tradeName === COMMODITY_NG_LONG_NAME ? 'Pipeline Gas' : tradeName,
    data: groupedData[tradeName],
    color: tradeName === COMMODITY_NG_LONG_NAME ? '#fbce07' : '#dd1d21',
    type: 'column',
    showInLegend: true,
    dataLabels: {
      enabled: true,
    },
  }));

  return [...columnChartSeriesData];
};

export const getBarChartCategories = (data: DataItem[]): string[] => {
  const quarters = data.map((el) => el.quarter);
  return [...new Set(quarters)];
};
