import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { fetchCountryLevelAverageMCR } from '../../../api/services';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { hideSpinner, showSpinner } from '../../../redux/datavisualization/datavisualizationSlice';
import Chart from '../../Chart/Chart';
import { ICountryLevelMCR, PointsData } from '../../../Types/types';
import {
  COUNTRY_CHART_SUBTITLE,
  COUNTRY_TITLE,
  GLOBAL_REGION,
  XAXIS_TEXT,
  YAXIS_TEXT,
  two,
} from '../../../constants';
import { toolTipFormatter } from '../../../utils';
import { notify } from '../../Toast/notify';
import { FETCH_ERROR } from '../../../utils/constants';
const CountryViewChart = () => {
  const [countryLevelAverageMCR, setCountryLevelAverageMCR] = useState<ICountryLevelMCR[]>();
  const [uniqueSources, setUniqueSources] = useState<string[]>([]);
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const fontFamilyValue = 'Shell Font, Arial, sans-serif';
  const left = 'left';

  const roundOffData = (countryLevelMcrData: ICountryLevelMCR[]) => {
    return countryLevelMcrData?.map((item: ICountryLevelMCR) => ({
      ...item,
      AVERAGE_MCR_PER_COUNTRY: +item.AVERAGE_MCR_PER_COUNTRY?.toFixed(two),
    }));
  };

  const filterData = (countryLevelMCRData: ICountryLevelMCR[]) => {
    return countryLevelMCRData?.filter((item: ICountryLevelMCR) => item.REGION !== GLOBAL_REGION);
  };

  const sortData = (countryLevelMCRFilterData: ICountryLevelMCR[]) => {
    return countryLevelMCRFilterData
      ?.slice()
      ?.sort((a, b) => b.AVERAGE_MCR_PER_COUNTRY - a.AVERAGE_MCR_PER_COUNTRY);
  };

  const fetchData = async () => {
    dispatch(showSpinner());
    try {
      const countryLevelMcrResponse = await fetchCountryLevelAverageMCR(
        loggedInUserRegion,
        userEvp,
      );
      const countryLevelMCRSortedData = sortData(
        filterData(roundOffData(countryLevelMcrResponse?.data)),
      );
      const sourcesSet =
        countryLevelMCRSortedData &&
        countryLevelMCRSortedData.length > 0 &&
        new Set(countryLevelMCRSortedData.map((item) => item.SOURCE));
      const uniqueSource = sourcesSet && Array.from(sourcesSet);
      uniqueSource && uniqueSource.length > 0 && setUniqueSources(uniqueSource);
      countryLevelMCRSortedData && setCountryLevelAverageMCR(countryLevelMCRSortedData);
    } catch {
      notify('error', FETCH_ERROR);
      dispatch(hideSpinner());
    } finally {
      dispatch(hideSpinner());
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const categoriesData = countryLevelAverageMCR?.map((item) => item.COUNTRY) ?? [];
  const seriesData = countryLevelAverageMCR?.map((item) => item.AVERAGE_MCR_PER_COUNTRY) ?? [null];
  const options: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 500,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px', // Adjust the font size here as per your requirement
        fontWeight: 'light',
        color: '#757575',
        fontFamily: fontFamilyValue,
      },
    },
    title: {
      text: COUNTRY_TITLE,
      align: left,
      style: {
        fontSize: '20px',
        fontFamily: fontFamilyValue,
      },
    },
    subtitle: {
      text: COUNTRY_CHART_SUBTITLE,
      align: left,
      style: {
        fontSize: '14px',
        fontFamily: fontFamilyValue,
      },
    },

    yAxis: {
      title: {
        useHTML: true,
        text: YAXIS_TEXT,
        style: {
          color: '#404040',
        },
      },
      gridLineWidth: 1,
      min: 0,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    xAxis: {
      categories: categoriesData,
      gridLineWidth: 1,
      title: {
        text: XAXIS_TEXT,
        useHTML: true,
        style: {
          color: '#404040',
        },
      },
      labels: {
        align: left,
        reserveSpace: true,
      },
    },

    series: [
      {
        type: 'column',
        color: '#5178A4',
        data: seriesData,
        name: 'MCR',
        showInLegend: false,
        dataLabels: {
          useHTML: true,
          animation: true,
          enabled: true,
          rotation: 0,
          align: 'right',
          padding: 10,
          color: '#FFFFFF',
          style: {
            fontSize: '10px',
            fontWeight: 'normal',
            textShadow: 'none',
            color: 'rgb(255, 255, 255)',
            textOutline: 'none',
          },
        },
      },
    ],
  };
  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    refreshChart();
  }, [uniqueSources]);

  return (
    <div data-testid='country-chart'>
      <Chart
        chartKey='MCRRanking'
        highcharts={Highcharts}
        options={options}
        source={uniqueSources.join(',')}
        key={chartKey}
      />
    </div>
  );
};

export default CountryViewChart;
