import React, { useEffect, useState } from 'react';
import { TCETileContainer, MTitle, CEdata } from '../CarbonDashBoard.styles';
import {
  TOTAL_CARBON_EMISSION,
  CARBON_BUDGET,
  CARBON_EMISSION,
  MARGIN_CARBON_RATIO,
  MARGIN_TO_CARBON_RATIO,
  NET_CARBON_INTENSITY,
  two,
} from '../../../constants';
import { carbonColor, lightenColor } from '../../../utils';
import Carbonemissionstat from '../../Carbonemissionstat';
import { ICarbonData, IDataYear } from '../../../Types/types';

interface CarbonEmissionCardProps {
  currentYear: number;
  emissionType: string;
  data: ICarbonData[];
}
const CarbonEmissionCard: React.FC<CarbonEmissionCardProps> = ({
  currentYear,
  emissionType,
  data,
}) => {
  const [responseData, setResponseData] = useState<ICarbonData[]>([]);
  //setting the current date and next 5yrs
  const yearsToAdd = 4;
  const nextYear = currentYear + yearsToAdd;
  useEffect(() => {
    setResponseData(data);
  }, [data]);

  // Total Carbon Emission calculation
  const carbonEmissionCurrentYear = responseData?.filter(
    (carbonEmissionData: IDataYear) =>
      carbonEmissionData.TRADE_YEAR >= currentYear && carbonEmissionData.TRADE_YEAR <= nextYear,
  )?.[0]?.[CARBON_EMISSION];
  const carbonBudgetCurrentYear = responseData?.filter(
    (carbonBudgetData: IDataYear) =>
      carbonBudgetData.TRADE_YEAR >= currentYear && carbonBudgetData.TRADE_YEAR <= nextYear,
  )?.[0]?.[CARBON_BUDGET];
  const percentMultiplier = 100;
  const carbonpercentCurrentYear =
    (carbonEmissionCurrentYear / carbonBudgetCurrentYear) * percentMultiplier;
  const carboncolor: string = carbonColor(carbonpercentCurrentYear);
  const lightenAmount = 90;
  const lightenedColorHex = lightenColor(carboncolor, lightenAmount);

  // Margin To Carbon Emission calculation
  const currentYearMarginRatio = responseData?.filter(
    (marginRatioData: IDataYear) => marginRatioData.TRADE_YEAR === currentYear,
  )?.[0]?.[MARGIN_CARBON_RATIO];

  // Carbon Emission Card data
  let title = TOTAL_CARBON_EMISSION;
  let statusLabel2 = 'MTPA';
  let statusLabel = carbonEmissionCurrentYear?.toFixed(two);
  let statusBgColor = lightenedColorHex;
  let textcolor = carboncolor;
  if (emissionType === MARGIN_TO_CARBON_RATIO) {
    title = MARGIN_TO_CARBON_RATIO;
    statusLabel2 = 'USD/T';
    statusLabel = currentYearMarginRatio?.toString();
    statusBgColor = '#F1F4F9';
    textcolor = '#4B78AA';
  } else if (emissionType === NET_CARBON_INTENSITY) {
    title = NET_CARBON_INTENSITY;
  }

  return (
    <TCETileContainer>
      <MTitle>{`${title} (${currentYear})`}</MTitle>
      <CEdata>
        <Carbonemissionstat
          statusLabel2={statusLabel2}
          statusLabel={statusLabel}
          statusBgColor={statusBgColor}
          textcolor={textcolor}
        />
      </CEdata>
    </TCETileContainer>
  );
};
export default CarbonEmissionCard;
