import {
  CounterpartyData,
  IAnalyticsFilterOptions,
  IAnalyticsHomeAction,
  IAnalyticsHomeData,
  IAnalyticsHomeDataPayload,
  ICptyFilteredValues,
  IFilterCPTYOptions,
  INcfByCountry,
  INcfFilteredValues,
  INcfMonthWise,
  INcfQuarterwise,
  RegionVolumeDetailsTableData,
  VolumeByRegionChartData,
  VolumeByRegionTableData,
  CounterpartyNcfCategoryData,
  DetailedViewParameter,
} from '../../Types/types';

const AnalyticsHomeActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IAnalyticsHomeData, payload: IAnalyticsHomeDataPayload) => IAnalyticsHomeData
> = {
  setNcfByCountry: (state, payload) => ({
    ...state,
    ncfByCountry: payload as INcfByCountry[],
  }),
  setNcfQuarterwise: (state, payload) => ({
    ...state,
    ncfQuarterwise: payload as INcfQuarterwise[],
  }),
  setNcfMonthWise: (state, payload) => ({
    ...state,
    ncfMonthWise: payload as INcfMonthWise[],
  }),
  setCounterpartyData: (state, payload) => ({
    ...state,
    counterpartyData: payload as CounterpartyData[],
  }),
  setCounterpartyNcfCategoryData: (state, payload) => ({
    ...state,
    counterpartyNcfCategoryData: payload as CounterpartyNcfCategoryData[],
  }),
  setCptyFilterOptions: (state, payload) => ({
    ...state,
    cptyFilterOptions: payload as IFilterCPTYOptions,
  }),
  setCptyFilteredValues: (state, payload) => ({
    ...state,
    cptyFilteredValues: payload as ICptyFilteredValues,
  }),
  setFilterOptions: (state, payload) => ({
    ...state,
    filterOptions: payload as IAnalyticsFilterOptions,
  }),
  setFilteredValues: (state, payload) => ({
    ...state,
    filteredValues: payload as INcfFilteredValues,
  }),
  setRegionVolumeDetailsTableData: (state, payload) => ({
    ...state,
    regionVolumeDetailsTableData: payload as RegionVolumeDetailsTableData[],
    isLoading: false,
  }),
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload as boolean,
  }),
  setVolumeByRegionChartData: (state, payload: IAnalyticsHomeDataPayload) => {
    if (typeof payload === 'object') {
      return {
        ...state,
        volumeByRegionChartData: {
          ...state.volumeByRegionChartData,
          ...payload,
        } as VolumeByRegionChartData,
      };
    }
    return state;
  },
  setVolumeByRegionTableData: (state, payload) => {
    if (typeof payload === 'object') {
      return {
        ...state,
        volumeByRegionTableData: {
          ...state.volumeByRegionTableData,
          ...payload,
        } as VolumeByRegionTableData,
      };
    }
    return state;
  },
  setAnalyticsHomeDetailedViewParameter: (state, payload) => ({
    ...state,
    detailedViewParameter: payload as DetailedViewParameter,
  }),
};
export const AnalyticsHomeReducers = (state: IAnalyticsHomeData, action: IAnalyticsHomeAction) => {
  if (Object.hasOwn(AnalyticsHomeActionHandlers, action.type)) {
    const handler = AnalyticsHomeActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
