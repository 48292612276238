import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flexbox, Grid, TextArea } from '@sede-x/shell-ds-react-framework';
import {
  ENTER_EXECUTIVE_SUMMARY,
  PUBLISH,
  STATUS_SUB_TITLE,
  STATUS_TITLE,
  UPDATED_ON,
} from '../../../constants';
import {
  CarbonStatus,
  MTitle,
  MSubTitle,
  TextAreaWrapper,
  TextAreaButtonWrapper,
} from '../CarbonDashBoard.styles';
import { Edit } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { formatDateTime, isEmailMatch } from '../../../utils';
import { overallCarbonStatus, saveCarbonStatus } from '../../../api/services';
import { IUserInfo } from '../../../Types/types';
import { DisabledTextarea } from './OveralCarbonStatus.styles';
import { notify } from '../../Toast/notify';
import { CARBON_STATUS_ERROR } from '../../../utils/constants';
import { useAppSelector } from '../../../redux/hooks';

interface CarbonStatusInfo {
  CONTENT: string;
  PUBLISHEDBY: string;
  PUBLISHEDON: string;
}
interface OverallCarbonStatusProps {
  user: IUserInfo;
  data: CarbonStatusInfo[];
}
const OverallCarbonStatus: React.FC<OverallCarbonStatusProps> = ({ user, data }) => {
  const [textAreaDisabled, setTextAreaDisabled] = useState(true);
  const [editRole, setEditRole] = useState(true);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [carbonStatusData, setCarbonStatusData] = useState<CarbonStatusInfo[]>();
  const { email } = user;
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);

  const upDateCarbonStatus = async () => {
    try {
      const carbonstatus = await overallCarbonStatus(loggedInUserRegion, userEvp);
      setCarbonStatusData(carbonstatus.data);
    } catch {
      notify('error', CARBON_STATUS_ERROR);
    }
  };
  useEffect(() => {
    const enteredValue = email.trim().toLowerCase() ?? '00000';
    const isMatch = isEmailMatch(enteredValue);
    setEditRole(!isMatch);
    setCarbonStatusData(data);
  }, [email, data]);

  useEffect(() => {
    setTextAreaValue(carbonStatusData?.[0]?.CONTENT ?? '');
  }, [carbonStatusData]);

  const changeTextAreaActive = () => {
    setTextAreaDisabled(false);
  };

  // Function to handle changes in the textarea value
  const handleTextAreaChange = (event: { target: { value: string } }) => {
    setTextAreaValue(event.target.value);
  };

  const onPublishConfirm = useCallback(async () => {
    const currentDate = new Date();
    const formattedDate = formatDateTime(currentDate);
    try {
      await saveCarbonStatus({
        CONTENT: textAreaValue,
        PUBLISHEDBY: email.trim().toLowerCase(),
        PUBLISHEDON: formattedDate,
      });
      upDateCarbonStatus();
    } catch (error) {
      notify('error', 'Error saving carbon status');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaValue, email]);
  const onPublish = () => {
    setTextAreaDisabled(true);
    onPublishConfirm();
  };
  const tenChars = 10;
  return (
    <CarbonStatus>
      <MTitle>{STATUS_TITLE}</MTitle>
      <Grid areas={['subtitle']}>
        <Grid.Cell area='subtitle'>
          <Flexbox justifyContent='space-between'>
            <MSubTitle>{STATUS_SUB_TITLE}</MSubTitle>
            <Button
              variant='transparent'
              className='editIcon'
              onClick={changeTextAreaActive}
              data-testid='edit-button'
            >
              {editRole === false && <Edit width={20} height={20} />}
            </Button>
          </Flexbox>
        </Grid.Cell>
      </Grid>
      <TextAreaWrapper>
        {textAreaDisabled === false ? (
          <TextArea
            invalid={textAreaValue.length === 0}
            disabled={textAreaDisabled}
            size='small'
            resize='vertical'
            rows={7}
            readOnly={false}
            maxLength={330}
            value={textAreaValue}
            name='supportTeam'
            onChange={handleTextAreaChange}
            placeholder={ENTER_EXECUTIVE_SUMMARY}
            data-testid='placeholder'
          />
        ) : (
          <DisabledTextarea rows={7} maxLength={330} readOnly value={textAreaValue} />
        )}
      </TextAreaWrapper>
      <TextAreaButtonWrapper>
        <Grid areas={['button']}>
          <Grid.Cell area='button'>
            <Flexbox justifyContent='space-between'>
              <div>
                <div
                  style={{
                    fontStyle: 'italic',
                    color: '#757575',
                    fontSize: 14,
                  }}
                >
                  {`${UPDATED_ON} ${carbonStatusData?.[0]?.PUBLISHEDON.substring(0, tenChars)}`}
                </div>
              </div>
              {textAreaValue.length > 0 && textAreaDisabled === false && (
                <Button sentiment='primary' size='medium' onClick={onPublish}>
                  {PUBLISH}
                </Button>
              )}
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </TextAreaButtonWrapper>
    </CarbonStatus>
  );
};

export default OverallCarbonStatus;
