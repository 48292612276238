import {
  Area,
  AreaData,
  CategoryData,
  CommodityData,
  CommodityDataEntry,
  CommodityYearData,
  ICategoryData,
  IChartDataItem,
  ICommodityData,
  IDataItem,
  IGroupNCI,
  IMovedCategoryData,
  IMovedData,
  IMovedKpiChartData,
  IMovedKpiDeals,
  IMovedKpiDealsData,
  ImportedDeals,
  IProduct,
  IuserRegionFlags,
  IYData,
  IYearData,
  IYearsData,
  TableData,
  TransformedCommodityItem,
} from '../../Types/types';
import { ALL_COUNTRIES, ALL_REGIONS, CARBON_KPI_CALCULATOR } from '../../api/constants';
import {
  BASE_CASE_NCI,
  IMPORTED_DEALS,
  NCI_RANGE,
  PIPELINE_GAS_DEAL,
  SCENARIO_NCI,
  commodityOrder,
  nine,
  one,
  pipeLineGas,
  power,
  ten,
  two,
} from '../../constants';
import { v4 as uuidv4 } from 'uuid';
import { convertValue } from './conversionutils';
import { formatRegionName, getRegionVal } from '../../utils';
interface TransformedBudgetLineItem {
  yearType: string;
  TRADE_YEAR: number;
  TRADE_COMMODITY_NAME: string;
  CARBON_BUDGET: number;
  CARBON_EMISSION: number;
  ORIGINAL_CARBON_EMISSION: number;
  QUANTITY: number;
  SOURCE: string;
  Year: number;
  type: string;
}
export const exampleData: Area[] = [
  {
    type: 'area',
    name: 'Hydrogen',
    data: [
      {
        yearType: '2023-plan',
        TRADE_YEAR: 2023,
        TRADE_COMMODITY_NAME: 'Hydrogen',
        CARBON_BUDGET: 685,
        CARBON_EMISSION: 0.006813,
        ORIGINAL_CARBON_EMISSION: 0.006813,
        QUANTITY: 13384.372178,
        SOURCE: 'PlanningOne',
        year: 2023,
        type: 'plan',
      },
    ],
  },
];
const currentYear = new Date().getFullYear();
const planningEndYear = currentYear + ten;
export const transformRagStatusData = (data: TransformedBudgetLineItem[]) => {
  return data
    .filter(
      (x: { TRADE_YEAR: number }) =>
        x.TRADE_YEAR !== null && x.TRADE_YEAR >= currentYear && x.TRADE_YEAR < planningEndYear,
    )
    .map(
      (item: {
        Year: number;
        TRADE_YEAR: number;
        CARBON_BUDGET: number;
        CARBON_EMISSION: number;
      }) => ({
        yearType: `${item.Year}-plan`,
        TRADE_YEAR: item.TRADE_YEAR,
        TRADE_COMMODITY_NAME: 'Hydrogen',
        CARBON_BUDGET: item.CARBON_BUDGET,
        CARBON_EMISSION: item.CARBON_EMISSION,
        ORIGINAL_CARBON_EMISSION: item.CARBON_EMISSION,
        QUANTITY: 0,
        SOURCE: 'PlanningOne',
        year: item.TRADE_YEAR,
        type: 'plan',
      }),
    );
};

const transformCommodityItem = (dataItem: CommodityYearData): TransformedCommodityItem => {
  const { Year, Trade_Commodity_Name, Carbon_Emissions, Original_Volume, Source } = dataItem;
  const yearType = dataItem.Year < currentYear ? 'historical' : 'plan';
  return {
    yearType: `${Year}-${yearType}`,
    TRADE_YEAR: Year,
    TRADE_COMMODITY_NAME: Trade_Commodity_Name,
    CARBON_BUDGET: 0,
    CARBON_EMISSION: Carbon_Emissions,
    ORIGINAL_CARBON_EMISSION: Carbon_Emissions,
    QUANTITY: Original_Volume,
    SOURCE: Source,
    year: Year,
    type: yearType,
  };
};

const transformCommodityDataItems = (data: CommodityYearData[]): TransformedCommodityItem[] => {
  return data
    .filter(
      (dataItem) =>
        dataItem.Year !== null &&
        dataItem.Year >= currentYear - 2 &&
        dataItem.Year < planningEndYear,
    )
    .map(transformCommodityItem);
};

export const transformCommodityData = (item: CommodityData) => {
  return {
    type: 'area',
    name: item.name,
    data: transformCommodityDataItems(item.data),
  };
};

export const findDataItemByRegionAndCategory = (
  data: IDataItem[],
  region: string,
  subregion: string,
) => {
  return data.find((item) => item.region === region && item.subregion === subregion);
};

export const getTransformedTableData = (sortedData: TableData[]) => {
  return sortedData.map(
    (item: { categoryData: CategoryData[]; Region: string; Carbon_Region: string }) => {
      return {
        region: item.Region,
        subregion: item.Carbon_Region,
        categoryData: item.categoryData.map((dataItem) => ({
          category: dataItem.Trade_Commodity_Name,
          product: dataItem.KPIs,
          yearsdata: Array.from(
            { length: planningEndYear - currentYear },
            (_, i) => i + currentYear,
          ).map((year) => {
            const yearsItem = dataItem.yearsdata.find((x: { Year: number }) => x.Year === year);
            return {
              id: uuidv4(),
              year,
              unit: yearsItem?.Original_Volume_UOM ?? '',
              originalValue:
                yearsItem?.Original_Volume != null
                  ? Number(yearsItem.Original_Volume.toFixed(two))
                  : 0,
              changedValue:
                yearsItem?.Original_Volume != null
                  ? Number(yearsItem.Original_Volume.toFixed(two))
                  : 0,
              carbonIntensity: yearsItem?.Carbon_Intensity ?? 0,
              carbonIntensityUom: yearsItem?.Carbon_Intensity_UOM ?? 'tCO2e/twh',
              originalEmission: Number(yearsItem?.Carbon_Emissions.toFixed(two) ?? 0),
              changedEmission: Number(yearsItem?.Carbon_Emissions.toFixed(two) ?? 0),
            };
          }),
        })),
      };
    },
  );
};

const filterCategoryData = (category: CategoryData): CategoryData => ({
  ...category,
  yearsdata: category.yearsdata.filter(
    (yearData) => yearData.Year !== null && yearData.Carbon_Intensity_UOM !== null,
  ),
});
const isCategoryDataNotEmpty = (category: CategoryData): boolean => category.yearsdata.length > 0;
export const transformRegionData = (
  accumulator: TableData[],
  region: { categoryData: CategoryData[] },
): TableData[] => {
  const filteredCategoryData = region?.categoryData
    ?.map(filterCategoryData)
    ?.filter(isCategoryDataNotEmpty);
  filteredCategoryData?.sort((a, b) => {
    return (
      commodityOrder?.indexOf(a.Trade_Commodity_Name) -
      commodityOrder?.indexOf(b.Trade_Commodity_Name)
    );
  });
  if (filteredCategoryData?.length > 0) {
    accumulator.push({
      Carbon_Region: '',
      Region: '',
      ...region,
      categoryData: filteredCategoryData,
    });
  }

  return accumulator;
};

export const addRecordIfNotExists = (
  existingData: CommodityDataEntry[],
  startYear: number,
  endYear: number,
) => {
  existingData.forEach((commodity) => {
    const commodityData = commodity.data;
    for (let year = startYear; year <= endYear; year++) {
      const yearExists = commodityData.some((entry) => entry.Year === year);

      if (!yearExists) {
        const newDataForYear = {
          Region: commodityData[0].Region,
          Year: year,
          Trade_Commodity_Name: commodity.name,
          Source: 'PlanningOne',
          Carbon_Intensity: 0,
          Original_Volume: 0,
          Carbon_Emissions: 0,
        };
        commodityData.push(newDataForYear);
      }
      commodityData.sort((a, b) => a.Year - b.Year);
    }
  });

  return existingData;
};

export const calculateCountryData = (data: IProduct[]): IProduct[] => {
  const allCountriesData: IProduct[] = [];

  const countryDataMap: { [key: string]: IProduct } = {};

  data.forEach((entry) => {
    entry.categoryData.forEach((category: ICategoryData) => {
      const product = category.product;
      const categoryKey = `${category.category}-${product}`;

      if (!countryDataMap[categoryKey]) {
        countryDataMap[categoryKey] = {
          region: ALL_REGIONS,
          subregion: '',
          categoryData: [
            {
              category: category.category,
              product,
              yearsdata: category.yearsdata.map((yearData) => ({
                ...yearData,
                id: uuidv4(), // Generate a new UUID
              })),
            },
          ],
        };
      } else {
        const existingCategoryData = countryDataMap[categoryKey].categoryData[0];
        category.yearsdata.forEach((yearData) => {
          const existingYearData = existingCategoryData.yearsdata.find(
            (ed: IYearData) => ed.year === yearData.year,
          );

          if (existingYearData) {
            existingYearData.originalValue =
              Number(existingYearData.originalValue) + Number(yearData.originalValue);
            existingYearData.changedValue =
              Number(existingYearData.changedValue) + Number(yearData.changedValue);
          } else {
            existingCategoryData.yearsdata.push({
              ...yearData,
              id: uuidv4(), // Generate a new UUID
            });
          }
        });
      }
    });
  });
  const countryDataArray = Object.values(countryDataMap);
  const allRegionsEntry: IProduct = {
    region: ALL_REGIONS,
    subregion: '',
    categoryData: countryDataArray.flatMap((entry) => entry.categoryData),
  };

  allCountriesData.push(allRegionsEntry);

  return allCountriesData;
};

export const updateChartDataWithEmissionModification = (
  chartDataWithEmission: IChartDataItem[],
  category: string,
  product: string,
  year: number,
  originalEmission: number | undefined,
  changedEmission: number | undefined,
  emissionMTPA: number | undefined,
) => {
  return chartDataWithEmission.map((item) => {
    if (item.name === category || item.name === product) {
      return {
        ...item,
        data: item.data.map((dataItem) => {
          if (dataItem.year === year) {
            const originalEmissionValue = originalEmission ?? 0;
            const changedEmissionValue = changedEmission ?? 0;
            const emissionMTPAValue = emissionMTPA ?? 0;
            let calcc = 0;
            if (originalEmission === changedEmission) {
              calcc = dataItem.CARBON_EMISSION - originalEmissionValue + emissionMTPAValue;
            } else {
              calcc = dataItem.CARBON_EMISSION - changedEmissionValue + emissionMTPAValue;
            }
            return { ...dataItem, CARBON_EMISSION: calcc };
          }
          return dataItem;
        }),
      };
    }
    return item;
  });
};

export const revertChartDataWithEmissionModification = (
  chartDataItem: IChartDataItem[],
  category: string,
  product: string,
  year: number,
  originalEmission: number | undefined,
  changedEmission: number | undefined,
) => {
  return chartDataItem.map((item) => {
    if (item.name === category || item.name === product) {
      return {
        ...item,
        data: item.data.map((dataItem) => {
          if (dataItem.year === year) {
            const originalEmissionValue = originalEmission ?? 0;
            const changedEmissionValue = changedEmission ?? 0;
            let calcc = 0;
            calcc = dataItem.CARBON_EMISSION - changedEmissionValue + originalEmissionValue;
            return { ...dataItem, CARBON_EMISSION: calcc };
          }
          return dataItem;
        }),
      };
    }
    return item;
  });
};

export const findYearDataByIdAndYear = (
  data: ICategoryData[],
  id: string,
  year: number,
): IYData | undefined => {
  for (const categoryData of data) {
    const yearData = categoryData.yearsdata.find(
      (myData) => myData.id === id && myData.year === year,
    );
    if (yearData && categoryData.category) {
      return { commodity: categoryData.category, yearsData: yearData };
    }
  }
  return undefined;
};
export const transformMovedDealsChart = (movedDeals: IMovedKpiDeals[]) => {
  return movedDeals.reduce((acc: IMovedKpiDealsData[], curr: IMovedKpiDeals) => {
    const existingObject = acc.find(
      (obj: IMovedKpiDealsData) => obj.name === curr.TRADE_COMMODITY_NAME,
    );
    const newObj = {
      Carbon_Emissions: curr.CARBON_EMISSION,
      Carbon_Intensity: curr.CARBON_INTENSITY,
      Original_Volume: curr.QUANTITY_UNIT_GWH,
      Source: CARBON_KPI_CALCULATOR,
      Trade_Commodity_Name: curr.TRADE_COMMODITY_NAME,
      Year: curr.TRADE_YEAR,
    };
    if (existingObject) {
      const indexByYear = existingObject.data.findIndex(
        (item: IMovedData) => item.Year === curr.TRADE_YEAR,
      );
      if (indexByYear !== -1) {
        const objByYear = existingObject.data[indexByYear];
        objByYear.Carbon_Emissions += curr.CARBON_EMISSION ?? 0;
        objByYear.Carbon_Intensity += curr.CARBON_INTENSITY ?? 0;
        objByYear.Original_Volume += curr.QUANTITY_UNIT_GWH ?? 0;
      } else {
        existingObject.data.push(newObj);
      }
    } else {
      acc.push({
        name: curr.TRADE_COMMODITY_NAME,
        data: [newObj],
      });
    }
    return acc.sort((a, b) => {
      if (a.name === PIPELINE_GAS_DEAL) {
        return -1;
      }
      if (b.name === PIPELINE_GAS_DEAL) {
        return 1;
      }
      return 0;
    });
  }, []);
};

export const transformedMovedDealsTable = (movedDealsData: IMovedKpiDeals[]) => {
  const movedDeals = movedDealsData.reduce((acc: IMovedKpiChartData[], curr: IMovedKpiDeals) => {
    const commodityName = curr.TRADE_COMMODITY_NAME;
    const yearsObj = {
      Carbon_Emissions: curr.CARBON_EMISSION,
      Carbon_UOM: curr.CARBON_EMISSION_UNIT,
      Carbon_Intensity: curr.CARBON_INTENSITY,
      Carbon_Intensity_UOM: curr.CARBON_INTENSITY_UNIT,
      Original_Volume: curr.QUANTITY_UNIT_GWH,
      Original_Volume_UOM: 'GWh',
      Year: curr.TRADE_YEAR,
    };
    // Find an existing object in the accumulator that has the same REGION_NAME and COUNTRY_NAME
    const existingObject = acc.find(
      (obj: IMovedKpiChartData) =>
        obj.Region === curr.REGION_NAME && obj.Carbon_Region === curr.CARBON_REGION,
    );

    if (existingObject) {
      // If such an object exists, push the current object into its 'data' array
      const getIndexByCommodity = existingObject.categoryData.findIndex(
        (item: IMovedCategoryData) => item.Trade_Commodity_Name === commodityName,
      );
      if (getIndexByCommodity !== -1) {
        const getIndexByYear = existingObject.categoryData[getIndexByCommodity].yearsdata.findIndex(
          (item: IYearsData) => item.Year === curr.TRADE_YEAR,
        );
        if (getIndexByYear !== -1) {
          const objByYear =
            existingObject.categoryData[getIndexByCommodity].yearsdata[getIndexByYear];
          objByYear.Carbon_Emissions += curr.CARBON_EMISSION ?? 0;
          objByYear.Carbon_Intensity += curr.CARBON_INTENSITY ?? 0;
          objByYear.Original_Volume += curr.QUANTITY_UNIT_GWH ?? 0;
        } else {
          existingObject.categoryData[getIndexByCommodity].yearsdata.push(yearsObj);
        }
      } else {
        existingObject.categoryData.push({
          Trade_Commodity_Name: commodityName,
          KPIs: commodityName,
          yearsdata: [yearsObj],
        });
      }
    } else {
      acc.push({
        Region: curr.REGION_NAME,
        Carbon_Region: curr.CARBON_REGION,
        categoryData: [
          {
            Trade_Commodity_Name: commodityName,
            KPIs: commodityName,
            yearsdata: [yearsObj],
          },
        ],
      });
    }

    return acc;
  }, []);

  return movedDeals?.map((data: IMovedKpiChartData) => {
    return {
      ...data,
      categoryData: data.categoryData.map((subItem: IMovedCategoryData) => ({
        ...subItem,
        Trade_Commodity_Name: IMPORTED_DEALS,
      })),
    };
  });
};

export const getMergedData = (
  tableData: IMovedKpiChartData[],
  transformedMovedDealsData: IMovedKpiChartData[],
) => {
  const result: IMovedKpiChartData[] = [];
  tableData.forEach((item1) => {
    const matchingItem = transformedMovedDealsData.find(
      (item2) => item1.Region === item2.Region && item1.Carbon_Region === item2.Carbon_Region,
    );

    if (matchingItem) {
      result.push({
        ...item1,
        categoryData: [...item1.categoryData, ...matchingItem.categoryData],
      });
    } else {
      result.push({ ...item1 });
    }
  });
  return result;
};

export const getNetCarbonIntensity = (netCarbonIntensityData: IGroupNCI[]) => {
  const rangeData: number[][] = [];
  const updatedNciData = netCarbonIntensityData?.map((item) => {
    rangeData.push([item?.GROUP_NCI_TARGET_HIGHER ?? null, item?.GROUP_NCI_TARGET_LOWER ?? null]);
    return {
      ...item,
      CHANGED_GROUP_VOLUME: item.GROUP_VOLUME,
      CHANGED_NET_ABSOLUTE_EMISSIONS: item.NET_ABSOLUTE_EMISSIONS,
      CHANGED_REPORTED_GROUP_NCI: item.REPORTED_GROUP_NCI,
    };
  });
  return [
    {
      type: 'line',
      name: BASE_CASE_NCI,
      data: updatedNciData,
    },
    {
      type: 'line',
      name: SCENARIO_NCI,
      data: updatedNciData,
    },
    {
      type: 'columnrange',
      name: NCI_RANGE,
      data: rangeData,
    },
  ];
};

export const calculateNCIData = (
  deltaVolumeInMj: number,
  deltaVolumeInMwh: number | null,
  year: number,
  carbonIntensity: number | null,
  netCarbonIntensityData: Area[],
  commodityName: string,
  carbonEmissionInMTPA?: number,
) => {
  const scenarioNciObj = netCarbonIntensityData.filter((item) => item.name === SCENARIO_NCI)[0];
  const scenarioNciObjByYear = (scenarioNciObj.data as IGroupNCI[]).filter(
    (item) => item.YEAR === year,
  )[0];
  const oldGroupVolume = scenarioNciObjByYear?.GROUP_VOLUME;
  const oldNetAbsEmission = scenarioNciObjByYear?.NET_ABSOLUTE_EMISSIONS;
  const newGroupVolume = oldGroupVolume + deltaVolumeInMj; // in mj;
  let newNetAbsEmission = oldNetAbsEmission;
  if (carbonEmissionInMTPA) {
    newNetAbsEmission += carbonEmissionInMTPA;
  } else if (deltaVolumeInMwh && carbonIntensity) {
    const deltaEmission = deltaVolumeInMwh * carbonIntensity; // tco2e;
    const deltaEmissionInMtpa = convertValue(
      deltaEmission,
      'tco2e',
      'mtpa',
      commodityName,
    ) as number;
    newNetAbsEmission += deltaEmissionInMtpa;
  }
  const newNetAbsEmissionInGco2e = convertValue(newNetAbsEmission, 'mtpa', 'gco2e', commodityName);
  const newNetCarbonIntensity =
    newNetAbsEmissionInGco2e && newNetAbsEmissionInGco2e / newGroupVolume; // gcoe2/mj
  const updatedData = {
    ...scenarioNciObjByYear,
    CHANGED_NET_ABSOLUTE_EMISSIONS: newNetAbsEmission,
    CHANGED_GROUP_VOLUME: newGroupVolume,
    CHANGED_REPORTED_GROUP_NCI: newNetCarbonIntensity,
  };
  return netCarbonIntensityData.map((item) => {
    if (item.name === SCENARIO_NCI) {
      return {
        ...item,
        data: (item.data as IGroupNCI[]).map((dataItem) =>
          dataItem.YEAR === year ? updatedData : dataItem,
        ),
      };
    }
    return item;
  });
};

export const revertNciChartData = (nciData: Area[], year: number) => {
  return nciData.map((item) => {
    if (item.name === SCENARIO_NCI) {
      return {
        ...item,
        data: (item.data as IGroupNCI[]).map((dataItem) =>
          dataItem.YEAR === year
            ? {
                ...dataItem,
                CHANGED_NET_ABSOLUTE_EMISSIONS: dataItem.NET_ABSOLUTE_EMISSIONS,
                CHANGED_GROUP_VOLUME: dataItem.GROUP_VOLUME,
                CHANGED_REPORTED_GROUP_NCI: dataItem.REPORTED_GROUP_NCI,
              }
            : dataItem,
        ),
      };
    }
    return item;
  });
};

export const getMergedCommodity = (
  transformedData: {
    type: string;
    name: string;
    data: TransformedCommodityItem[];
  }[],
) => {
  const combinedCommodity = [];
  for (const obj of transformedData) {
    if (!obj.name.includes('Deal')) {
      combinedCommodity.push(...obj.data);
    }
  }
  const commodityData: TransformedCommodityItem[] = [];
  combinedCommodity.forEach((cItem: TransformedCommodityItem) => {
    const indexByYear = commodityData.findIndex((tItem) => tItem.TRADE_YEAR === cItem.TRADE_YEAR);

    if (indexByYear !== -1) {
      commodityData[indexByYear] = {
        ...commodityData[indexByYear],
        ORIGINAL_CARBON_EMISSION:
          commodityData[indexByYear].ORIGINAL_CARBON_EMISSION + cItem.ORIGINAL_CARBON_EMISSION,
      };
    } else {
      commodityData.push(cItem);
    }
  });
  return commodityData.map((item) => {
    if (item.yearType.includes('historical')) {
      return { ...item, CARBON_EMISSION: 0, ORIGINAL_CARBON_EMISSION: 0, QUANTITY: 0 };
    }
    return item;
  });
};
const ignoreSourceForCurrentYear = ['ReportOne', 'Tanso', 'PowerIntensityModel'];
export const getMergedOverllPortfolio = (commodityData: CommodityDataEntry[]) => {
  return commodityData.map((commodity) => {
    const updatedData = commodity.data.filter(
      (item) => !(item.Year === currentYear && ignoreSourceForCurrentYear.includes(item.Source)),
    );
    const yearMap = updatedData.reduce((acc, item) => {
      if (acc[item.Year]) {
        acc[item.Year].Carbon_Emissions += item.Carbon_Emissions;
        acc[item.Year].Source = `${acc[item.Year].Source},${item.Source}`;
      } else {
        acc[item.Year] = { ...item };
      }
      return acc;
    }, {} as { [year: number]: ICommodityData });

    const summedData = Object.values(yearMap);

    return {
      ...commodity,
      data: summedData,
    };
  });
};

export const addEmptyValueForKpiDeals = (dataObject: IMovedKpiDealsData[]) => {
  const startYear = currentYear - two;
  const endYear = currentYear + nine;
  const years = Array.from({ length: endYear - startYear + one }, (_, i) => startYear + i);

  dataObject.forEach((item) => {
    const existingYears = item.data.map((d) => d.Year);

    years.forEach((year) => {
      if (!existingYears.includes(year)) {
        item.data.push({
          Year: year,
          Carbon_Emissions: 0,
          Carbon_Intensity: 0,
          Original_Volume: 0,
          Source: '',
          Trade_Commodity_Name: item.name,
        });
      }
    });
    // Sort the data by year in ascending order
    item.data.sort((a, b) => a.Year - b.Year);
  });
  return dataObject;
};

export const sumFieldsByYear = (deals: ImportedDeals[]) => {
  const sumsByYear: {
    [key: number]: {
      TRADE_YEAR: number;
      TRADE_COMMODITY_NAME: string;
      CARBON_EMISSION: number;
      QUANTITY_UNIT_GWH: number;
    };
  } = {};

  deals.forEach((deal) => {
    const { TRADE_YEAR, CARBON_EMISSION, CARBON_EMISSION_UNIT, TRADE_COMMODITY_NAME } = deal;
    const carbonEmissionInMTPA = convertValue(
      CARBON_EMISSION,
      CARBON_EMISSION_UNIT,
      'mtpa',
      TRADE_COMMODITY_NAME,
    );
    if (!sumsByYear[TRADE_YEAR]) {
      sumsByYear[TRADE_YEAR] = {
        TRADE_YEAR,
        TRADE_COMMODITY_NAME: 'Power & Pipeline Gas',
        CARBON_EMISSION: 0,
        QUANTITY_UNIT_GWH: 0,
      };
    }

    sumsByYear[TRADE_YEAR].CARBON_EMISSION += carbonEmissionInMTPA ?? 0;
    sumsByYear[TRADE_YEAR].QUANTITY_UNIT_GWH += deal.QUANTITY_UNIT_GWH ?? 0;
  });

  return Object.values(sumsByYear);
};

export const getNciChartData = (filteredDeals: ImportedDeals[], nciTransformData: Area[]) => {
  let updatedNciData = nciTransformData;
  const summedDealsByYear = sumFieldsByYear(filteredDeals);
  summedDealsByYear.forEach((deal) => {
    const { TRADE_YEAR, TRADE_COMMODITY_NAME, QUANTITY_UNIT_GWH, CARBON_EMISSION } = deal;
    const deltaVolumeInMj = convertValue(
      QUANTITY_UNIT_GWH,
      'GWh',
      `mj${TRADE_YEAR}`,
      TRADE_COMMODITY_NAME,
    );
    updatedNciData = calculateNCIData(
      deltaVolumeInMj as number,
      null,
      TRADE_YEAR,
      null,
      updatedNciData,
      TRADE_COMMODITY_NAME,
      CARBON_EMISSION,
    );
  });
  return updatedNciData;
};

export const getBusinessPlanOptions = (
  dealsData: IProduct[],
  region: string,
  userRegionFlags: IuserRegionFlags,
) => {
  const userRegion = getRegionVal(userRegionFlags);
  if (dealsData && dealsData.length > 0) {
    const matchedDeals = dealsData.filter((x) => x.region.toUpperCase() === region);

    if (matchedDeals.length > 0) {
      return matchedDeals.map((x, ind) => ({ id: ind, value: x.subregion }));
    }
  }

  return userRegion !== ''
    ? [{ id: 0, value: formatRegionName(userRegion) }]
    : [{ id: 0, value: formatRegionName(region) }];
};

// Helper functions
const filterDealsByRegion = (deals: ImportedDeals[], region: string) => {
  return region !== ''
    ? deals.filter((deal) => deal.CARBON_REGION.toUpperCase() === region)
    : deals;
};

const filterDealsByStartYear = (deals: ImportedDeals[], year: number) => {
  return deals.filter((deal) => {
    const startYear = new Date(deal.START_DATE).getFullYear();
    return startYear >= year;
  });
};

const filterDealsByRegionName = (deals: ImportedDeals[], region: string) => {
  return region === ALL_REGIONS ? deals : deals.filter((deal) => deal.REGION_NAME === region);
};

const getUniqueCountries = (deals: ImportedDeals[]) => {
  const uniqueCountries = new Set<string>();
  return deals.reduce((acc, deal, index) => {
    if (!uniqueCountries.has(deal.COUNTRY_NAME)) {
      uniqueCountries.add(deal.COUNTRY_NAME);
      acc.push({ id: index, value: deal.COUNTRY_NAME });
    }
    return acc;
  }, [] as { id: number; value: string }[]);
};

const adjustSubRegionsForUserRegion = (
  subRegions: { id: number; value: string }[],
  filteredDeals: ImportedDeals[],
  region: string,
) => {
  if (!filteredDeals.length && region.toLowerCase() === subRegions[0].value.toLowerCase()) {
    subRegions.pop();
  }
  subRegions.unshift({ id: 0, value: ALL_COUNTRIES });
};

export const getDealFunnelOptions = (
  dealsData: ImportedDeals[],
  region: string,
  userRegionFlags: IuserRegionFlags,
) => {
  const userRegion = getRegionVal(userRegionFlags);

  const updatedDealsData = filterDealsByRegion(dealsData, userRegion);
  const dealsFilteredByStartYear = filterDealsByStartYear(updatedDealsData, currentYear);
  const filteredDeals = filterDealsByRegionName(dealsFilteredByStartYear, region);

  let subRegions;

  if (filteredDeals.length > 0) {
    subRegions = getUniqueCountries(filteredDeals);
  } else if (userRegion !== '') {
    subRegions = [{ id: 0, value: userRegion }];
  } else {
    subRegions = [
      { id: 0, value: region === ALL_REGIONS ? ALL_REGIONS : formatRegionName(region) },
    ];
  }

  if (userRegion === '') {
    adjustSubRegionsForUserRegion(subRegions, filteredDeals, region);
  }

  return subRegions;
};

export const convertCarbonEmissionInMTPA = (deal: ImportedDeals) => {
  const { CARBON_EMISSIONS, CARBON_EMISSION_UNIT, TRADE_COMMODITY_NAME } = deal;
  const carbonEmissionInMTPA = convertValue(
    CARBON_EMISSIONS as number,
    CARBON_EMISSION_UNIT,
    'MTPA',
    TRADE_COMMODITY_NAME,
  );
  return {
    ...deal,
    CARBON_EMISSION: carbonEmissionInMTPA,
    CARBON_EMISSIONS: carbonEmissionInMTPA,
    MAX_CARBON_EMISSIONS: carbonEmissionInMTPA,
  };
};
export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
const hasMatchingYear = (yearsdata: { year: number }[], targetYear: number): boolean => {
  return yearsdata.some(({ year }) => year === targetYear);
};
export const handleInputValAndChartOnTabChange = (
  regionData: IProduct[],
  scenerioChartData: Area[],
) => {
  const groupedData = new Map();
  const regionDataCopy = deepCopy(regionData);
  regionDataCopy[0].categoryData.forEach((item) => {
    const key = item.category; // Group by category only
    if (!groupedData.has(key)) {
      groupedData.set(key, { ...item, yearsdata: [...item.yearsdata] });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const existingItem = groupedData.get(key)!;
      item.yearsdata.forEach((yearData) => {
        const existingYearData = existingItem.yearsdata.find(
          ({ year }: { year: number }) => year === yearData.year,
        );
        if (existingYearData) {
          existingYearData.changedEmission += yearData.changedEmission;
        } else {
          existingItem.yearsdata.push(yearData);
        }
      });
    }
  });
  const transformedData = Array.from(groupedData.values());
  return scenerioChartData.map((scenarioData) => {
    if ([pipeLineGas, power].includes(scenarioData.name)) {
      (scenarioData.data as AreaData[]).forEach((dataEntry) => {
        const transformedEntry = transformedData.find(
          (entry) =>
            entry.category === scenarioData.name &&
            hasMatchingYear(entry.yearsdata, dataEntry.year),
        );
        const changedEmission = transformedEntry?.yearsdata.find(
          ({ year }: { year: number }) => year === dataEntry.year,
        )?.changedEmission;
        if (transformedEntry) {
          dataEntry.CARBON_EMISSION = changedEmission ?? 0;
        }
      });
    }
    return scenarioData;
  });
};
