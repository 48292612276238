import React, { useEffect } from 'react';
import { Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import Footer from '../Footer/Footer';
import './commodityOverview.css';
import { fetchCommodityOverview, fetchCarbonIntensityTarget } from '../../api/services';
import Loader from '../Loader/Loader';
import { ALL, SE_GLOBAL_SBTI } from '../../api/constants';
import { BorderDiv } from './commodityOverview.style';
import FilterSection from './FilterSection';
import { filterTargetDataByRegion, getCommodityVal, getFilterOptions, setEvpValue } from './Utils';
import { AllCharts } from './AllCharts';
import { resetState, setField } from '../../redux/commodityDetails/commodityDetailsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hideSpinner, showSpinner } from '../../redux/datavisualization/datavisualizationSlice';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import { DasboardContainer } from '../CarbonDashBoard/CarbonDashBoard.styles';
import { CommodityFilteredValue } from '../../redux/commodityDetails/commodityDetails.types';
import { formatRegionName } from '../../utils';

const CommodityOverview = () => {
  const dispatch = useAppDispatch();
  const { loggedInUserRegion, userEvp, showEvpData, isCpmRegionFieldDisabled } = useAppSelector(
    (state) => state.dashboard,
  );
  const fetchCommodityOverviewData = async () => {
    dispatch(showSpinner());
    try {
      const commodityoverview = await fetchCommodityOverview(loggedInUserRegion, userEvp);
      const commodityOverviewApi = commodityoverview.data;
      const commodityOptions = getCommodityVal(commodityOverviewApi);
      const commodityVal = commodityOptions[0];
      const commodityOverview = commodityOverviewApi.map(
        (x: { COUNTRY: string | null; REGION: string }) => ({
          ...x,
          COUNTRY:
            x.COUNTRY === null
              ? `${formatRegionName(x.REGION.replace('AFRICA', 'EUROPE'))}-Other`
              : x.COUNTRY,
        }),
      );
      const commodityFiltered = commodityOverview.filter(
        (x: { TRADE_COMMODITY_NAME: string }) => x.TRADE_COMMODITY_NAME === commodityVal,
      );
      const commodityFilteredByRegion = commodityFiltered.filter((item: CommodityFilteredValue) =>
        item.IPU.toUpperCase().includes(loggedInUserRegion),
      );
      dispatch(
        setField({
          masterCommodityFilteredValue: commodityOverview,
          nonCommodityFilteredValue: commodityFiltered,
          commodityFilteredValue: commodityFiltered,
          sauData: commodityFilteredByRegion,
        }),
      );
      const sauOptions = getFilterOptions(commodityFiltered, 'SAU');
      const evpOptions = ['ALL', ...getFilterOptions(commodityFiltered, 'EVP')];
      const seIpuRegionOptions = ['ALL', ...getFilterOptions(commodityFiltered, 'SE_IPU_Region')];
      const businessOptions = [
        'ALL',
        ...getFilterOptions(commodityFiltered, 'REPORTING_ENTITY_NAME'),
      ];
      const region = isCpmRegionFieldDisabled ? seIpuRegionOptions[1] : ALL;
      dispatch(
        setField({
          commodityVal: commodityVal as string,
          commodityOptions,
          sauVal: sauOptions[0],
          sauId: sauOptions[0],
          sauOptions,
          evpVal: setEvpValue(evpOptions, showEvpData),
          seIpuRegionVal: region,
          bizVal: 'ALL',
          evpOptions,
          seIpuRegionOptions,
          businessOptions,
        }),
      );
      // Getting carbon intensity target values
      const carbonintensityTargetResp = await fetchCarbonIntensityTarget(
        loggedInUserRegion,
        userEvp,
      );
      const carbonintensityTargetData = carbonintensityTargetResp.data;
      dispatch(setField({ key: 'carbonIntensityTarget', value: carbonintensityTargetData }));

      const filterData = filterTargetDataByRegion(SE_GLOBAL_SBTI, carbonintensityTargetData);
      dispatch(setField({ key: 'carIntTargetPerRegion', value: filterData }));
    } catch {
      notify('error', FETCH_ERROR);
    }
    dispatch(hideSpinner());
  };

  useEffect(() => {
    fetchCommodityOverviewData();
    // Reset the state when the component is unmounted
    dispatch(resetState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader />
      <Grid>
        <DasboardContainer>
          <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
            <FilterSection />
            <BorderDiv />
            <AllCharts />
          </Flexbox>
        </DasboardContainer>
      </Grid>
      <Footer />
    </>
  );
};

export default CommodityOverview;
