import { IHeaderMenuWithSubMenu, IUserInfo } from '../Types/types';
export const FOOTER_TEXT =
  '© Shell Energy Integration & Fundamentals (SEIF) Carbon Portfolio Management. ';
export const FOOTER_SUB_TEXT = 'Confidential data and graphs. Not for onward distribution.';
export const WELCOME_TITLE = 'Welcome to the';
export const CARBON_DASHBOARD = 'Carbon Dashboard ';
export const LINK_TEXT = 'How do we calculate carbon budget?';
export const STATUS_TITLE = 'Status';
export const STATUS_SUB_TITLE = 'Executive Summary.';
export const TOTAL_CARBON_EMISSION = 'Total Carbon Emissions';
export const NET_CARBON_INTENSITY = 'Net Carbon Intensity';
export const AVG_PLANNING_MCR_IPU = 'Average Planning MCR per IPU';
export const BUSINESS_SPLIT_TITLE = 'Business Split';
export const BUSINESS_SPLIT_SUB_TITLE = 'In Scope Volume per Integrated Performance Unit (TWh).';
export const MARGIN_TO_CARBON_RATIO = 'Margin to Carbon Ratio';
export const COMMODITY_SPLIT_TITLE = 'Commodity Split';
export const COMMODITY_SPLIT_SUB_TITLE =
  'Current year Net Absolute Emissions per commodity (mtpa).';
export const emailList = [
  'swati.tyagi@shell.com',
  'm.sunnypanguluri@shell.com',
  'katherine.langworthy@shell.com',
  'yifan.y.wang@shell.com',
  'james.perkins2@shell.com',
  'rama-prasad.nayak@shell.com',
  'md.m.irfan@shell.com',
  'camilla.torre@shell.com',
  'nitish.n.raj@shell.com',
  'sk-sabir.ali@shell.com',
  'amit.sarur@shell.com',
  'binu-jose.zachariah@shell.com',
  'abhilasha.kanaskar@shell.com',
];
export const CARBON_EMISSION = 'CARBON_EMISSION';
export const QUANTITY = 'QUANTITY';
export const CARBON_BUDGET = 'CARBON_BUDGET';
export const MARGIN_CARBON_RATIO = 'MARGIN_CARBON_RATIO';
export const PLANNING_ONE_SOURCE = 'PlanningOne';
export const PARIGFINANCEREPORT = 'PARIGFinance&Reporting';
export const PUBLISH = 'Publish';
export const UPDATED_ON = 'Updated on';
export const ENTER_EXECUTIVE_SUMMARY = 'Enter executive summary';
export const R_ES_GLOBAL_COE_CORP = 'R&ES Global COE & Corporate';
export const COMMODITY_OVERVIEW = 'Commodity Overview';
export const SCENARIO_MODELLING = 'Scenario Modelling';
export const USER_DATA: IUserInfo = {
  at_hash: 'abcdef',
  aud: 'example_audience',
  auth_time: 1631500000,
  azp: 'example_azp',
  email: 'user@example.com',
  email_verified: true,
  exp: 1631600000,
  iat: 1631500000,
  iss: 'example_issuer',
  jti: 'example_jti',
  preferred_username: 'example_user',
  session_state: 'example_session',
  sid: 'example_sid',
  sub: '12345',
  typ: 'example_type',
  scope: 'test',
  allowed_origins: [],
  realm_access: { roles: [] },
  resource_access: { account: { roles: [] } },
  user: {
    at_hash: 'Ty3r8SYikbz0SoOPtS5YdQ',
    aud: 'TANSO_LOCAL',
    auth_time: 1694583446,
    azp: 'TANSO_LOCAL',
    email: 'md.m.irfan@shell.com',
    email_verified: false,
    exp: 1694584535,
    iat: 1694584235,
    iss: 'https://sso.dep.shell/auth/realms/dep',
    jti: 'c71197c2-bec2-4be3-babc-31f0328b8136',
    preferred_username: 'inmir5',
    session_state: '82d81ef8-278e-4801-b07e-f014f731a694',
    sid: '82d81ef8-278e-4801-b07e-f014f731a694',
    sub: '533eb9f9-1ced-44c5-8536-5375c737ea0f',
    typ: 'ID',
    scope: 'test',
    allowed_origins: [],
    realm_access: { roles: [] },
    resource_access: { account: { roles: [] } },
  },
};
export const LOGOUT = 'logout';
export const TITLE_DASHBOARD = 'CPM';
export const SUB_TITLE_DASHBOARD = 'Shell Energy';
export const TABS_MENU = [
  {
    key: 'Dashboard',
    label: 'Dashboard',
  },
  {
    key: 'CommodityOverview',
    label: COMMODITY_OVERVIEW,
  },
  {
    key: 'ScenarioModel',
    label: SCENARIO_MODELLING,
  },
  {
    key: 'Tanso',
    label: 'Tanso',
  },
  {
    key: 'TansoApp',
    label: 'Tanso App',
  },
];
export const routerMenuItems: IHeaderMenuWithSubMenu[] = [
  { key: '0', label: 'Dashboard', path: '/Dashboard' },
  { key: '1', label: COMMODITY_OVERVIEW, path: '/CommodityOverView' },
  { key: '2', label: SCENARIO_MODELLING, path: '/ScenarioModel' },
  {
    label: 'NCF Reporting',
    key: '3',
    children: [
      { key: '3-1', label: 'View and Edit Report', path: '/viewEditReport' },
      { key: '3-2', label: 'Audit Report', path: '/viewauditreport' },
    ],
  },
];

export const FEEDBACK = 'Feedback';
export const GLOBAL_REGION = 'GLOBAL';
export const COUNTRY_TITLE = 'MCR Ranking';
export const COUNTRY_CHART_SUBTITLE = 'A view of average Margin to Carbon Ratio (MCR) by Country.';
export const YAXIS_TEXT = 'Margin to Carbon Ratio (USD/Tonne)';
export const XAXIS_TEXT = 'Country';
export const LINE_CHART_TITLE = 'Planning MCR per IPU';
export const LINE_CHART_SUB_TITLE =
  'An overview of Margin to Carbon Ratio for each Integrated Performance Unit per year.';
export const LINE_CHART_YAXIS_TEXT = 'Margin to Carbon Ratio (USD/Tonne)';
export const API_ERROR = 'api is not available';
export const SE_IPU_REGION_TABLE_COLUMNS = [
  { label: 'IPU Region' },
  { label: 'Latest Estimate' },
  { label: 'Plan' },
  { label: 'Delta' },
];
export const COMMODITY_TABLE_COLUMNS = [
  { label: 'Commodity' },
  { label: 'Latest Estimate' },
  { label: 'Plan' },
  { label: 'Delta' },
];
export const SCENERIO_TABLE_SOURCE = 'Scenario Table';
export const SCENERIO_MODELLING_TITLE = SCENARIO_MODELLING;
export const SCENERIO_MODELLING_SUB_TITLE =
  'Use this tool to model different scenarios against the baseline portfolio to understand the carbon impact.';
export const COMMODITY_TITLE = COMMODITY_OVERVIEW;
export const COMMODITY_SUB_TITLE =
  'A detailed overview of a commodity that allows you to understand current inputs and performance.';
export const TITLE_FONT_FAMILY = 'Shell Font';
export const CHART_AXIS_STYLE = {
  fontFamily: TITLE_FONT_FAMILY,
  fontSize: '11px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'right',
  color: '#343434',
};

export const VIEW_DETAILS = 'View Details';
export const VIEW_IMPORTED_DEALS = 'View All Imported Deals';
export const point1 = 0.1;
export const point2 = 0.2;
export const point3 = 0.3;
export const point4 = 0.4;
export const point5 = 0.5;
export const point6 = 0.6;
export const point7 = 0.7;
export const point8 = 0.8;
export const point9 = 0.9;
export const zero = 0;
export const one = 1;
export const two = 2;
export const three = 3;
export const four = 4;
export const five = 5;
export const six = 6;
export const seven = 7;
export const eight = 8;
export const nine = 9;
export const ten = 10;
export const oneOne = 11;
export const hundred = 100;
export const fiveHundred = 500;
export const twoThousand = 2000;
export const oneTwoThree = 123;
export const oneTwo = 12;
export const power = 'Power';
export const naturalGas = 'Natural Gas';
export const pipeLineGas = 'Pipeline Gas';
export const renewableGas = 'Renewable Gas';
export const CarbonBudget = 'Carbon Budget';
export const PIPELINE_GAS_DEAL = 'Pipeline Gas (Deal)';
export const commodityOrder = [
  'Pipeline Gas (Deal)',
  'Power (Deal)',
  'Pipeline Gas',
  'Power',
  'Total Plan',
  'Latest Estimate (LE)',
];
export const IMPORTED_DEALS = 'Imported Deals';
export const colorMapping: { [key: string]: string } = {
  'Pipeline Gas (Americas)': '#D6A200',
  'Pipeline Gas (Europe)': '#FFC600',
  'Pipeline Gas (Asia)': '#FFDF55',
  'Pipeline Gas (Australia)': '#FFF7B4',
  'Power (Europe)': '#336094',
  'Power (Americas)': '#10355C',
  'Power (Asia)': '#6E94C0',
  'Power (Australia)': '#ACC3DE',
};

export const initialRegionData = {
  AMERICAS: {
    'Pipeline Gas Americas': [],
    'Power Americas': [],
  },
  ASIA: {
    'Pipeline Gas Asia': [],
    'Power Asia': [],
  },
  AUSTRALIA: {
    'Pipeline Gas Australia': [],
    'Power Australia': [],
  },
  EUROPE: {
    'Pipeline Gas Europe': [],
    'Power Europe': [],
  },
};

export const carbonBudgetLineData = {
  type: 'line',
  color: '#D93638',
  name: CarbonBudget,
  dashStyle: 'Dash',
  data: [],
};
export const regionOptions = ['ALL', 'Americas', 'Europe', 'Australia', 'Asia'];
export const SHELL_ENERGY_TRADING = 'Shell Energy Trading';
export const GAS_DISCLAIMER =
  'Please note Shell Energy Trading Actuals are currently missing for Asia.';
export const POWER_DISCLAIMER =
  'Please note that the Shell Energy Trading Actuals for Asia currently cover Japan only.';
export const CONTRACTED_VS_PLAN_DISCLAIMER =
  'Please note contracted volumes from ETRMs are only available for Shell Energy Trading (and SET Asia volumes currently only include Japan).';
export const BASE_CASE_NCI = 'Base Case NCI';
export const SCENARIO_NCI = 'Scenario NCI';
export const NCI_RANGE = 'NCI Range';
export const BUSINESS_PLAN = 'Business Plan';
export const BUSINESS_PLAN_KEY = 'businessPlan';
export const DEAL_FUNNEL = 'Deal Funnel';
export const DEAL_FUNNEL_KEY = 'dealFunnel';
export const CARBON_LEAVERS = 'Carbon Levers';
export const CARBON_LEAVERS_KEY = 'carbonLeavers';
export const quarters = [
  { quarter: one, months: [two, three, four] },
  { quarter: two, months: [five, six, seven] },
  { quarter: three, months: [eight, nine, ten] },
  { quarter: four, months: [oneOne, oneTwo, one] },
];
export const BUSINESS_PLAN_TITLE =
  'Latest Operating Plan sales volumes of different commodities (aka Base Case). Modify them to build a scenario and visualize impact on Lifecycle Carbon Emissions.';
export const DEAL_FUNNEL_TITLE =
  'Funnel Deals activated on the Carbon KPI Calculator. Enable/disable them to build a scenario and visualize impact on Lifecycle Carbon Emissions.';

export const GAS_COMMODITY_ORDER = ['Renewable Gas', 'Pipeline Gas'];
