import { FormField, Select } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { DefaultOptionType } from '../../Types/types';
import { updateCommidityFilterOption } from '../../utils';

interface SelectFormFieldProps {
  id: string;
  label: string;
  value: string;
  options: string[];
  // eslint-disable-next-line no-unused-vars
  onChange: (event: string, option: DefaultOptionType) => void;
  loading?: boolean;
  disabled?: boolean;
}
const SelectFormField: React.FC<SelectFormFieldProps> = ({
  id,
  label,
  value,
  options,
  onChange,
  loading,
  disabled,
}: SelectFormFieldProps) => (
  <FormField id={id} label={label}>
    <Select
      size='medium'
      allowClear={false}
      value={value}
      onChange={(e, option) => onChange(e, option as DefaultOptionType)}
      loading={loading}
      disabled={disabled}
    >
      {options.map((item) => (
        <option key={item} value={item}>
          {updateCommidityFilterOption(label, item)}
        </option>
      ))}
    </Select>
  </FormField>
);

export default SelectFormField;
